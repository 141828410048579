/**
 * This file contains mappings between podcast IDs and their custom URL slugs
 * Used for creating user-friendly URLs like /podcasts/nikhilKamath instead of /podcasts/uuid
 */

// Map from podcast ID to custom name
export const podcastIdToName: Record<string, string> = {
  // Add your podcast ID to name mappings here
  // Replace these example UUIDs with your actual podcast IDs
  
  "e75d1a7c-6524-4168-b6f1-ee6bd023dde0": "nikhilKamath",
  "608b6256-e08a-4320-9b4e-290146baf418": "gregIsenberg",
  "6ba7b811-9dad-11d1-80b4-00c04fd430c8": "mastersOfScale",
  "6ba7b812-9dad-11d1-80b4-00c04fd430c8": "allIn",
  "6ba7b813-9dad-11d1-80b4-00c04fd430c8": "hubermanLab",
};

// Map from custom name to podcast ID (reverse mapping)
export const podcastNameToId: Record<string, string> = 
  Object.entries(podcastIdToName).reduce(
    (acc, [id, name]) => ({
      ...acc,
      [name]: id
    }), 
    {}
  );

/**
 * Get a custom name for a podcast ID
 * Falls back to the ID if no custom name is defined
 */
export const getPodcastNameFromId = (id: string): string => {
  return podcastIdToName[id] || id;
};

/**
 * Get a podcast ID from a custom name
 * Returns the input if it's not a known custom name (assuming it's already an ID)
 */
export const getPodcastIdFromName = (nameOrId: string): string => {
  return podcastNameToId[nameOrId] || nameOrId;
}; 