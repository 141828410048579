import { useEffect, useState, useRef, useCallback } from 'react';
import { Idea } from '../types';
import { getAllIdeas } from '../services/api';
import { IdeaCard } from './idea/IdeaCard';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from './ui/carousel';
import type { CarouselApi } from './ui/carousel';

interface FeaturedIdeasCarouselProps {
  className?: string;
}

export function FeaturedIdeasCarousel({ className }: FeaturedIdeasCarouselProps) {
  const [featuredIdeas, setFeaturedIdeas] = useState<Idea[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [api, setApi] = useState<CarouselApi | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const autoRotationIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Function to check if screen is small
  const checkScreenSize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < 768); // md breakpoint in Tailwind
  }, []);

  // Set up auto-rotation for small screens
  useEffect(() => {
    // Initial check
    checkScreenSize();

    // Add event listener for resize
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
      // Clear any existing interval when component unmounts
      if (autoRotationIntervalRef.current) {
        clearInterval(autoRotationIntervalRef.current);
      }
    };
  }, [checkScreenSize]);

  // Set up auto-rotation when api is available and screen size changes
  useEffect(() => {
    // Clear any existing interval
    if (autoRotationIntervalRef.current) {
      clearInterval(autoRotationIntervalRef.current);
      autoRotationIntervalRef.current = null;
    }

    // Only set up auto-rotation on small screens
    if (isSmallScreen && api) {
      autoRotationIntervalRef.current = setInterval(() => {
        api.scrollNext();
      }, 3000); // Rotate every 3 seconds
    }

    return () => {
      if (autoRotationIntervalRef.current) {
        clearInterval(autoRotationIntervalRef.current);
      }
    };
  }, [api, isSmallScreen]);

  useEffect(() => {
    const fetchFeaturedIdeas = async () => {
      try {
        setIsLoading(true);
        // Fetch ideas sorted by upvotes to get the most popular ones
        const response = await getAllIdeas({
          page: 1,
          sortBy: 'upvotes',
          sortOrder: 'desc',
        });
        // Take only the first 4 ideas
        setFeaturedIdeas(response.items.slice(0, 4));
        setIsLoading(false);
      } catch (err) {
        console.error('Failed to fetch featured ideas:', err);
        setError('Failed to load featured ideas');
        setIsLoading(false);
      }
    };

    fetchFeaturedIdeas();
  }, []);

  if (isLoading) {
    return (
      <div className="w-full py-12 flex justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return null; // Don't show anything if there's an error
  }

  if (featuredIdeas.length === 0) {
    return null; // Don't show anything if there are no ideas
  }

  return (
    <div className={`w-full max-w-[1400px] mx-auto px-4 ${className}`}>
      <h2 className="text-3xl font-bold text-center mb-8">Featured Ideas</h2>
      <Carousel
        opts={{
          align: 'start',
          loop: true,
        }}
        setApi={setApi}
        className="w-full"
      >
        <CarouselContent>
          {featuredIdeas.map((idea) => (
            <CarouselItem key={idea.id} className="md:basis-1/2 lg:basis-1/3 xl:basis-1/4">
              <div className="p-1 h-full">
                <IdeaCard idea={idea} />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="flex justify-center gap-2 mt-4">
          <CarouselPrevious className="static translate-y-0 mx-2" />
          <CarouselNext className="static translate-y-0 mx-2" />
        </div>
      </Carousel>
    </div>
  );
} 