import { useState, useEffect } from 'react';
import { Episode, Idea, Insight, Quote, Product } from '../../types/index';
import { getIdeas, getInsights, getQuotes, getProducts } from '../../services/api';
import { IdeaCard } from '../idea/IdeaCard';

// Helper function to ensure URLs are absolute
const ensureAbsoluteUrl = (url: string): string => {
  if (!url) return '';
  return url.startsWith('http://') || url.startsWith('https://') 
    ? url 
    : `https://${url}`;
};

interface EpisodeModalProps {
  episode: Episode;
  onClose: () => void;
}

export const EpisodeModal = ({ episode, onClose }: EpisodeModalProps) => {
  const [ideas, setIdeas] = useState<Idea[]>([]);
  const [insights, setInsights] = useState<Insight[]>([]);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState({
    ideas: true,
    insights: true,
    quotes: true,
    products: true
  });
  const [isVisible, setIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<'ideas' | 'insights' | 'quotes' | 'products'>('ideas');

  useEffect(() => {
    // Trigger enter animation
    requestAnimationFrame(() => {
      setIsVisible(true);
    });

    const fetchData = async () => {
      try {
        // Fetch ideas
        const fetchedIdeas = await getIdeas(episode.id);
        setIdeas(fetchedIdeas);
        setLoading(prev => ({ ...prev, ideas: false }));
        
        // Fetch insights
        const fetchedInsights = await getInsights(episode.id);
        setInsights(fetchedInsights);
        setLoading(prev => ({ ...prev, insights: false }));
        
        // Fetch quotes
        const fetchedQuotes = await getQuotes(episode.id);
        setQuotes(fetchedQuotes);
        setLoading(prev => ({ ...prev, quotes: false }));
        
        // Fetch products
        const fetchedProducts = await getProducts(episode.id);
        setProducts(fetchedProducts);
        setLoading(prev => ({ ...prev, products: false }));
      } catch (error) {
        console.error('Failed to fetch episode data:', error);
      }
    };

    fetchData();
  }, [episode.id]);

  const handleClose = () => {
    setIsVisible(false);
    // Wait for exit animation to complete
    setTimeout(onClose, 300);
  };

  return (
    <div 
      className={`fixed inset-0 bg-[#F5F5F2] z-50 transition-transform duration-300 ease-in-out ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      }`}
    >
      <div className="h-screen overflow-y-auto">
        {/* Header */}
        <div className="sticky top-0 bg-white/10 backdrop-blur-sm border-b border-gray-200 z-10">
          <div className="container mx-auto px-4 py-4 flex justify-between items-center">
            <button
              onClick={handleClose}
              className="text-gray-600 hover:text-gray-900 flex items-center gap-2 transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-6">
          {/* Episode Section */}
          <div className="max-w-4xl mx-auto mb-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-6">{episode.title}</h1>
            
            <div className="aspect-video mb-8">
              <iframe
                src={episode.youtube_url.replace('watch?v=', 'embed/')}
                title={episode.title}
                className="w-full h-full rounded-lg shadow-lg"
                allowFullScreen
              />
            </div>

            {episode.guest_name && (
              <p className="text-xl text-gray-700 mb-4">
                Guest: {episode.guest_name}
              </p>
            )}

            {/* <p className="text-gray-600 mb-8 text-lg">{episode.description}</p> */}

            {episode.summary && (
              <div className="bg-white rounded-lg shadow-sm p-6 mb-12">
                <h3 className="font-semibold text-xl text-gray-900 mb-3">Summary</h3>
                <p className="text-gray-600">{episode.summary}</p>
              </div>
            )}
          </div>

          {/* Tabs */}
          <div className="max-w-4xl mx-auto mb-8">
            <div className="flex border-b border-gray-200">
              <button
                className={`px-4 py-2 font-medium text-sm ${
                  activeTab === 'ideas'
                    ? 'text-blue-600 border-b-2 border-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setActiveTab('ideas')}
              >
                Business Ideas
              </button>
              <button
                className={`px-4 py-2 font-medium text-sm ${
                  activeTab === 'insights'
                    ? 'text-blue-600 border-b-2 border-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setActiveTab('insights')}
              >
                Insights
              </button>
              <button
                className={`px-4 py-2 font-medium text-sm ${
                  activeTab === 'quotes'
                    ? 'text-blue-600 border-b-2 border-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setActiveTab('quotes')}
              >
                Quotes
              </button>
              <button
                className={`px-4 py-2 font-medium text-sm ${
                  activeTab === 'products'
                    ? 'text-blue-600 border-b-2 border-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setActiveTab('products')}
              >
                Products Mentioned
              </button>
            </div>
          </div>

          {/* Content based on active tab */}
          <div className="max-w-4xl mx-auto">
            {/* Ideas Tab */}
            {activeTab === 'ideas' && (
              <>
                <h2 className="text-3xl font-bold mb-8 text-gray-900">Business Ideas</h2>
                
                {loading.ideas ? (
                  <div className="flex justify-center py-12">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-600"></div>
                  </div>
                ) : ideas.length > 0 ? (
                  <div className="grid gap-6">
                    {ideas.map((idea) => (
                      <IdeaCard
                        key={idea.id}
                        idea={idea}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="bg-white rounded-lg shadow-sm p-12 text-center">
                    <p className="text-gray-600 text-lg">
                      No business ideas found for this episode.
                    </p>
                  </div>
                )}
              </>
            )}

            {/* Insights Tab */}
            {activeTab === 'insights' && (
              <>
                <h2 className="text-3xl font-bold mb-8 text-gray-900">Key Insights</h2>
                
                {loading.insights ? (
                  <div className="flex justify-center py-12">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-600"></div>
                  </div>
                ) : insights.length > 0 ? (
                  <div className="grid gap-6">
                    {insights.map((insight) => (
                      <div key={insight.id} className="bg-white rounded-lg shadow-sm p-6">
                        <h3 className="font-semibold text-xl text-gray-900 mb-3">{insight.title}</h3>
                        <p className="text-gray-600 mb-4">{insight.content}</p>
                        
                        {insight.supporting_quotes && insight.supporting_quotes.length > 0 && (
                          <div className="mt-4 pt-4 border-t border-gray-100">
                            <h4 className="font-medium text-gray-700 mb-2">Supporting Quotes:</h4>
                            <ul className="list-disc pl-5 space-y-2">
                              {insight.supporting_quotes.map((quote, index) => (
                                <li key={index} className="text-gray-600 italic">"{quote}"</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="bg-white rounded-lg shadow-sm p-12 text-center">
                    <p className="text-gray-600 text-lg">
                      No insights found for this episode.
                    </p>
                  </div>
                )}
              </>
            )}

            {/* Quotes Tab */}
            {activeTab === 'quotes' && (
              <>
                <h2 className="text-3xl font-bold mb-8 text-gray-900">Notable Quotes</h2>
                
                {loading.quotes ? (
                  <div className="flex justify-center py-12">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-600"></div>
                  </div>
                ) : quotes.length > 0 ? (
                  <div className="grid gap-6">
                    {quotes.map((quote) => (
                      <div key={quote.id} className="bg-white rounded-lg shadow-sm p-6">
                        <blockquote className="text-xl italic font-medium text-gray-700 mb-3">
                          "{quote.text}"
                        </blockquote>
                        <div className="flex justify-between items-center">
                          <cite className="text-gray-600 not-italic">— {quote.speaker || 'Speaker'}</cite>
                          {quote.timestamp && (
                            <span className="text-gray-500 text-sm">{quote.timestamp}</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="bg-white rounded-lg shadow-sm p-12 text-center">
                    <p className="text-gray-600 text-lg">
                      No quotes found for this episode.
                    </p>
                  </div>
                )}
              </>
            )}

            {/* Products Tab */}
            {activeTab === 'products' && (
              <>
                <h2 className="text-3xl font-bold mb-8 text-gray-900">Products Mentioned</h2>
                
                {loading.products ? (
                  <div className="flex justify-center py-12">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-600"></div>
                  </div>
                ) : products.length > 0 ? (
                  <div className="grid gap-6">
                    {products.map((product) => (
                      <div key={product.id} className="bg-white rounded-lg shadow-sm p-6">
                        <div className="flex justify-between items-start">
                          <h3 className="font-semibold text-xl text-gray-900 mb-2">{product.name}</h3>
                          {product.price && typeof product.price === 'number' && (
                            <span className="bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded">
                              ${product.price.toFixed(2)}
                            </span>
                          )}
                        </div>
                        
                        {product.category && (
                          <div className="mb-3">
                            <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded">
                              {product.category}
                            </span>
                          </div>
                        )}
                        
                        <p className="text-gray-600 mb-4">{product.description}</p>
                        
                        {product.url && (
                          <a 
                            href={ensureAbsoluteUrl(product.url)} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="inline-flex items-center text-blue-600 hover:underline"
                          >
                            Visit Website
                            <svg className="w-3 h-3 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
                              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
                            </svg>
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="bg-white rounded-lg shadow-sm p-12 text-center">
                    <p className="text-gray-600 text-lg">
                      No products mentioned in this episode.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}; 