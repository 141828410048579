import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card"
import { Badge } from "../components/ui/badge"
import { LightBulbIcon, CodeBracketIcon, RocketLaunchIcon } from "@heroicons/react/24/outline"
import UserEmailsList from "../components/auth/UserEmailsList"

const techStack = {
  frontend: [
    { name: "React", description: "UI Library" },
    { name: "TypeScript", description: "Type Safety" },
    { name: "Tailwind CSS", description: "Styling" },
    { name: "Shadcn UI", description: "Component Library" },
    { name: "React Router", description: "Routing" },
  ],
  backend: [
    { name: "FastAPI", description: "API Framework" },
    { name: "Python", description: "Backend Language" },
    { name: "PostgreSQL", description: "Database" },
    { name: "SQLAlchemy", description: "ORM" },
    { name: "OpenAI", description: "AI Integration" },
  ],
  deployment: [
    { name: "Netlify", description: "Frontend Hosting" },
    { name: "Railway", description: "Backend Hosting" },
    { name: "GitHub Actions", description: "CI/CD" },
  ]
}

const roadmap = [
  {
    title: "Q2 2024",
    features: [
      "Enhanced AI analysis for deeper insights",
      "User collections and bookmarks",
      "Advanced search and filtering",
      "Email notifications for new insights"
    ]
  },
  {
    title: "Q3 2024",
    features: [
      "Custom AI models for specific industries",
      "API access for developers",
      "Community features and discussions",
      "Mobile app development"
    ]
  }
]

export default function TeamPage() {
  return (
    <div className="min-h-screen bg-[#F5F5F2] py-12">
      <div className="container mx-auto px-4 max-w-[1400px]">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h1 className="text-5xl font-quincycf-bold mb-6 tracking-tight">Meet the Creator</h1>
          <p className="text-gray-600 text-xl leading-relaxed">
            Hi! I'm Omkar Rajmane, a full-stack developer passionate about AI and building products that help people learn and grow.
          </p>
        </div>

        <Tabs defaultValue="journey" className="w-full">
          <TabsList className="grid w-full grid-cols-3 mb-8 bg-white/80 p-1 rounded-xl">
            <TabsTrigger value="journey" className="text-base py-3 data-[state=active]:bg-black data-[state=active]:text-white rounded-lg">The Journey</TabsTrigger>
            <TabsTrigger value="tech" className="text-base py-3 data-[state=active]:bg-black data-[state=active]:text-white rounded-lg">Tech Stack</TabsTrigger>
            <TabsTrigger value="roadmap" className="text-base py-3 data-[state=active]:bg-black data-[state=active]:text-white rounded-lg">Roadmap</TabsTrigger>
          </TabsList>

          <TabsContent value="journey">
            <Card className="border-none shadow-lg bg-white rounded-2xl overflow-hidden">
              <CardHeader className="bg-[#E8EDED] pb-6">
                <CardTitle className="text-3xl font-quincycf-bold">Building PodThinker</CardTitle>
                <CardDescription className="text-lg">From Idea to Reality</CardDescription>
              </CardHeader>
              <CardContent className="space-y-8 p-8">
                <div className="space-y-4">
                  <div className="flex items-start gap-4">
                    <div className="flex items-center justify-center w-12 h-12 bg-[#E8EDED] rounded-xl flex-shrink-0 mt-1">
                      <LightBulbIcon className="w-6 h-6 text-gray-700" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-3">The Inspiration</h3>
                      <p className="text-gray-600 text-lg leading-relaxed">
                        As an avid podcast listener and entrepreneur enthusiast, I found myself constantly taking notes while listening to business podcasts. I realized there was an opportunity to use AI to automatically extract valuable insights and make them searchable and actionable.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="flex items-start gap-4">
                    <div className="flex items-center justify-center w-12 h-12 bg-[#E8D9D9] rounded-xl flex-shrink-0 mt-1">
                      <CodeBracketIcon className="w-6 h-6 text-gray-700" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-3">The Development Process</h3>
                      <p className="text-gray-600 text-lg leading-relaxed">
                        PodThinker started as a weekend project but quickly evolved into a full-fledged application. I focused on creating a clean, intuitive interface while leveraging advanced AI capabilities to provide meaningful insights from podcast content.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="flex items-start gap-4">
                    <div className="flex items-center justify-center w-12 h-12 bg-[#DCD9E8] rounded-xl flex-shrink-0 mt-1">
                      <RocketLaunchIcon className="w-6 h-6 text-gray-700" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-3">Challenges & Learnings</h3>
                      <p className="text-gray-600 text-lg leading-relaxed">
                        Building PodThinker has been a journey of continuous learning - from optimizing AI processing costs to ensuring accurate transcriptions and meaningful insights. Each challenge has led to improvements in both the product and my skills as a developer.
                      </p>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="tech">
            <div className="grid gap-6 md:grid-cols-3">
              <Card className="border-none shadow-lg bg-white rounded-2xl overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                <CardHeader className="bg-[#E8EDED] pb-6">
                  <CardTitle className="text-2xl font-semibold">Frontend</CardTitle>
                  <CardDescription className="text-base">User Interface Technologies</CardDescription>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="flex flex-wrap gap-2">
                    {techStack.frontend.map((tech) => (
                      <Badge key={tech.name} className="text-sm bg-[#E8EDED] text-gray-800 hover:bg-gray-300 px-3 py-1">
                        {tech.name}
                      </Badge>
                    ))}
                  </div>
                </CardContent>
              </Card>

              <Card className="border-none shadow-lg bg-white rounded-2xl overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                <CardHeader className="bg-[#E8D9D9] pb-6">
                  <CardTitle className="text-2xl font-semibold">Backend</CardTitle>
                  <CardDescription className="text-base">Server & AI Technologies</CardDescription>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="flex flex-wrap gap-2">
                    {techStack.backend.map((tech) => (
                      <Badge key={tech.name} className="text-sm bg-[#E8D9D9] text-gray-800 hover:bg-gray-300 px-3 py-1">
                        {tech.name}
                      </Badge>
                    ))}
                  </div>
                </CardContent>
              </Card>

              <Card className="border-none shadow-lg bg-white rounded-2xl overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                <CardHeader className="bg-[#DCD9E8] pb-6">
                  <CardTitle className="text-2xl font-semibold">Deployment</CardTitle>
                  <CardDescription className="text-base">Infrastructure & DevOps</CardDescription>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="flex flex-wrap gap-2">
                    {techStack.deployment.map((tech) => (
                      <Badge key={tech.name} className="text-sm bg-[#DCD9E8] text-gray-800 hover:bg-gray-300 px-3 py-1">
                        {tech.name}
                      </Badge>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </TabsContent>

          <TabsContent value="roadmap">
            <div className="grid gap-6 md:grid-cols-2">
              {roadmap.map((quarter, index) => (
                <Card key={quarter.title} className="border-none shadow-lg bg-white rounded-2xl overflow-hidden hover:shadow-xl transition-all duration-300">
                  <CardHeader className={`${index === 0 ? 'bg-[#E8EDED]' : 'bg-[#DCD9E8]'} pb-6`}>
                    <CardTitle className="text-2xl font-semibold">{quarter.title}</CardTitle>
                    <CardDescription className="text-base">Upcoming Features & Improvements</CardDescription>
                  </CardHeader>
                  <CardContent className="p-6">
                    <ul className="space-y-3">
                      {quarter.features.map((feature, idx) => (
                        <li key={idx} className="flex items-start gap-3">
                          <span className="h-2 w-2 rounded-full bg-blue-600 mt-2.5 flex-shrink-0"></span>
                          <span className="text-gray-700 text-lg">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </div>
          </TabsContent>
        </Tabs>

        <div className="mt-24 bg-white rounded-2xl shadow-lg p-10 text-center">
          <h2 className="text-3xl font-quincycf-bold mb-6">Want to Contribute?</h2>
          <p className="text-gray-600 text-lg mb-8 max-w-2xl mx-auto leading-relaxed">
            PodThinker is open to contributions! Whether you're a developer, designer, or have ideas to share,
            I'd love to hear from you.
          </p>
          <a
            href="https://github.com/yourusername/podthinker"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-8 py-4 rounded-full bg-black text-white hover:opacity-90 transition-all duration-300 text-lg font-medium"
          >
            View on GitHub →
          </a>
        </div>
      </div>
    </div>
  )
} 