import axios, { AxiosError } from 'axios';
import { Podcast, Idea, Insight, Quote, Product } from '../types/index';
import { Episode as EpisodeType } from '../types/index';
import { supabase } from '../lib/supabase';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8000';
// Ensure HTTPS in production
const secureApiUrl = import.meta.env.PROD ? API_URL.replace('http://', 'https://') : API_URL;

export const api = axios.create({
  baseURL: `${secureApiUrl}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  maxRedirects: 5
});

// Add request interceptor for authentication
api.interceptors.request.use(
  async (config) => {
    // Get Supabase session
    const { data: { session } } = await supabase.auth.getSession();
    
    if (session?.access_token) {
      config.headers.Authorization = `Bearer ${session.access_token}`;
    } else {
      // Fallback to legacy token if exists
      const legacyToken = localStorage.getItem('token');
      if (legacyToken) {
        config.headers.Authorization = `Bearer ${legacyToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for better error handling
api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    console.error('API Error:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers,
      config: error.config
    });

    // Handle 401 Unauthorized errors
    if (error.response?.status === 401) {
      // Clear the legacy token if it exists
      localStorage.removeItem('token');
      
      // Get current path and search params
      const currentPath = window.location.pathname + window.location.search;
      
      // Debug log
      console.log('Unauthorized redirect:', {
        currentPath,
        fullUrl: window.location.href,
        redirectUrl: `/login?returnTo=${encodeURIComponent(currentPath)}`
      });

      // Redirect to login page with the return URL
      window.location.href = `/login?returnTo=${encodeURIComponent(currentPath)}`;
    }

    return Promise.reject(error);
  }
);

export const login = async (email: string, password: string): Promise<{ access_token: string }> => {
  try {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);

    const response = await api.post('/auth/login/', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    
    const { access_token } = response.data;
    localStorage.setItem('token', access_token);
    return response.data;
  } catch (error) {
    console.error('Failed to login:', error);
    throw error;
  }
};

export const logout = async (): Promise<void> => {
  try {
    // Clear legacy token if it exists
    localStorage.removeItem('token');

    // Attempt to sign out from Supabase
    // This will handle clearing the session and local storage
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Supabase logout error:', error);
      throw error;
    }
  } catch (error) {
    console.error('Failed to logout:', error);
    throw error;
  }
};

export const getPodcasts = async (): Promise<Podcast[]> => {
  try {
    const response = await api.get('/podcasts/');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch podcasts:', error);
    throw error;
  }
};

export const getPodcast = async (id: string): Promise<Podcast> => {
  try {
    const response = await api.get(`/podcasts/${id}/`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch podcast ${id}:`, error);
    throw error;
  }
};

/**
 * Fetches episodes for a specific podcast
 * @param id - The podcast ID (not the custom name)
 * @returns A promise that resolves to an array of episodes
 */
export const getEpisodes = async (id: string): Promise<EpisodeType[]> => {
  try {
    const response = await api.get(`/episodes/${id}/`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch episodes for podcast ${id}:`, error);
    throw error;
  }
};

export const getIdeas = async (episodeId: string): Promise<Idea[]> => {
  try {
    const response = await api.get(`/ideas/episode/${episodeId}/`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch ideas for episode ${episodeId}:`, error);
    throw error;
  }
};

export const getInsights = async (episodeId: string): Promise<Insight[]> => {
  try {
    const response = await api.get(`/insights/${episodeId}/`);
    return response.data.items || [];
  } catch (error) {
    console.error(`Failed to fetch insights for episode ${episodeId}:`, error);
    return [];
  }
};

export const getQuotes = async (episodeId: string): Promise<Quote[]> => {
  try {
    const response = await api.get(`/quotes/${episodeId}/`);
    return response.data.items || [];
  } catch (error) {
    console.error(`Failed to fetch quotes for episode ${episodeId}:`, error);
    return [];
  }
};

export const getProducts = async (episodeId: string): Promise<Product[]> => {
  try {
    const response = await api.get(`/products/${episodeId}/`);
    return response.data.items || [];
  } catch (error) {
    console.error(`Failed to fetch products for episode ${episodeId}:`, error);
    
    // Log more detailed error information
    if (error instanceof Error) {
      console.error('Error details:', {
        message: error.message,
        name: error.name,
        stack: error.stack,
      });
      
      // If it's an AxiosError, log response data
      if ('response' in error && error.response && typeof error.response === 'object') {
        const axiosError = error as AxiosError;
        console.error('Server response:', {
          status: axiosError.response?.status,
          data: axiosError.response?.data,
        });
      }
    }
    
    // Return empty array instead of throwing to prevent component crashes
    return [];
  }
};

export const updateIdeaVotes = async (ideaId: string, type: 'upvote' | 'downvote'): Promise<Idea> => {
  try {
    const response = await api.post(`/ideas/${ideaId}/vote/`, { type });
    return response.data;
  } catch (error) {
    console.error('Failed to update vote:', error);
    throw error;
  }
};

interface GetIdeasParams {
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  businessArea?: string;
  keyword?: string;
}

export const getAllIdeas = async (params: GetIdeasParams = {}) => {
  try {
    const queryParams = new URLSearchParams();
    
    if (params.page) queryParams.set('page', params.page.toString());
    if (params.sortBy) queryParams.set('sort_by', params.sortBy);
    if (params.sortOrder) queryParams.set('sort_order', params.sortOrder);
    if (params.businessArea) queryParams.set('business_area', params.businessArea);
    if (params.keyword) queryParams.set('keyword', params.keyword);

    const response = await api.get(`/ideas/?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch ideas:', error);
    throw error;
  }
};

export const getBusinessAreas = async (): Promise<string[]> => {
  const startTime = performance.now();
  try {
    console.log('[getBusinessAreas] Starting business areas fetch');
    
    // Check cache first
    const cachedAreas = localStorage.getItem('businessAreas');
    const cacheTimestamp = localStorage.getItem('businessAreasTimestamp');
    
    // Cache is valid for 24 hours
    const CACHE_DURATION = 12 * 60 * 60 * 1000; // 24 hours in milliseconds
    // const CACHE_DURATION = 1000; // 1 milliseconds
    if (cachedAreas && cacheTimestamp) {
      const timeSinceCache = Date.now() - Number(cacheTimestamp);
      const isValid = timeSinceCache < CACHE_DURATION;
      
      // console.log('[getBusinessAreas] Cache status:', {
      //   isValid,
      //   timeSinceCache: `${Math.round(timeSinceCache / 1000 / 60)} minutes`,
      //   cacheExpiresIn: `${Math.round((CACHE_DURATION - timeSinceCache) / 1000 / 60)} minutes`
      // });
      
      if (isValid) {
        const parsedAreas = JSON.parse(cachedAreas);
        console.log('[getBusinessAreas] Cache hit, returning', {
          areaCount: parsedAreas.length,
          areas: parsedAreas
        });
        return parsedAreas;
      }
    } else {
      // console.log('[getBusinessAreas] No cache found or cache timestamp missing');
    }
    
    // If cache is invalid or doesn't exist, fetch from API
    // console.log('[getBusinessAreas] Fetching from API');
    const response = await api.get('/ideas/business-areas');
    const areas = response.data;
    
    // console.log('[getBusinessAreas] API response received', {
    //   status: response.status,
    //   areaCount: areas.length,
    //   areas
    // });
    
    // Update cache
    localStorage.setItem('businessAreas', JSON.stringify(areas));
    localStorage.setItem('businessAreasTimestamp', Date.now().toString());
    // console.log('[getBusinessAreas] Cache updated');
    
    // const endTime = performance.now();
    // console.log('[getBusinessAreas] Operation completed in', {
    //   duration: `${Math.round(endTime - startTime)}ms`
    // });
    
    return areas;
  } catch (error) {
    console.error('[getBusinessAreas] Error fetching business areas:', {
      error,
      errorMessage: error instanceof Error ? error.message : 'Unknown error',
      errorStack: error instanceof Error ? error.stack : undefined
    });
    
    // If API call fails, try to use cached data even if expired
    const cachedAreas = localStorage.getItem('businessAreas');
    if (cachedAreas) {
      console.log('[getBusinessAreas] Falling back to expired cache');
      const parsedAreas = JSON.parse(cachedAreas);
      console.log('[getBusinessAreas] Returning expired cache data', {
        areaCount: parsedAreas.length,
        areas: parsedAreas
      });
      return parsedAreas;
    }
    
    const endTime = performance.now();
    console.log('[getBusinessAreas] Operation failed in', {
      duration: `${Math.round(endTime - startTime)}ms`
    });
    
    throw error;
  }
};

export interface PaginatedResponse<T> {
  items: T[];
  total: number;
  page: number;
  page_size: number;
  total_pages: number;
}

// Track in-flight save requests to prevent duplicates
const inFlightSaveRequests = new Map<string, Promise<any>>();

// Original save idea function
const _saveIdea = async (ideaId: string): Promise<Idea> => {
  const response = await api.post(`/ideas/${ideaId}/save/`);
  return response.data;
};

// Wrapped save idea function with request deduplication
export const saveIdea = async (ideaId: string): Promise<Idea> => {
  // If there's already an in-flight request for this idea, return that promise
  if (inFlightSaveRequests.has(ideaId)) {
    console.log(`Using existing save request for idea: ${ideaId}`);
    return inFlightSaveRequests.get(ideaId)!;
  }

  // Create a new request promise
  const savePromise = _saveIdea(ideaId)
    .finally(() => {
      // Remove from in-flight requests after a short delay
      setTimeout(() => {
        inFlightSaveRequests.delete(ideaId);
      }, 500);
    });

  // Store the promise in the map
  inFlightSaveRequests.set(ideaId, savePromise);
  
  return savePromise;
};

// Original unsave idea function
const _unsaveIdea = async (ideaId: string): Promise<Idea> => {
  const response = await api.delete(`/ideas/${ideaId}/save/`);
  return response.data;
};

// Wrapped unsave idea function with request deduplication
export const unsaveIdea = async (ideaId: string): Promise<Idea> => {
  // If there's already an in-flight request for this idea, return that promise
  if (inFlightSaveRequests.has(ideaId)) {
    console.log(`Using existing unsave request for idea: ${ideaId}`);
    return inFlightSaveRequests.get(ideaId)!;
  }

  // Create a new request promise
  const unsavePromise = _unsaveIdea(ideaId)
    .finally(() => {
      // Remove from in-flight requests after a short delay
      setTimeout(() => {
        inFlightSaveRequests.delete(ideaId);
      }, 500);
    });

  // Store the promise in the map
  inFlightSaveRequests.set(ideaId, unsavePromise);
  
  return unsavePromise;
};

export const getSavedIdeas = async (page: number = 1, pageSize: number = 10): Promise<PaginatedResponse<Idea>> => {
  console.log('getSavedIdeas called with page:', page, 'pageSize:', pageSize);
  try {
    const response = await api.get(`/ideas/saved/`, {
      params: {
        page,
        page_size: pageSize
      }
    });
    console.log('getSavedIdeas response:', response.data);
    return response.data;
  } catch (error) {
    console.error('getSavedIdeas error:', error);
    throw error;
  }
};

export const subscribeToNewsletter = async (email: string, source: string = 'footer'): Promise<{ status: string; message: string }> => {
  try {
    const response = await api.post('/subscribers/', {
      email,
      source
    });
    return response.data;
  } catch (error) {
    console.error('Failed to subscribe to newsletter:', error);
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.detail || 'Failed to subscribe. Please try again.');
    }
    throw new Error('Failed to subscribe. Please try again.');
  }
}; 