import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Idea } from '../types';
import { IdeaCard } from '../components/idea/IdeaCard';
import { getSavedIdeas } from '../services/api';
import { Button } from '../components/ui/button';
import { useAuth } from '../contexts/AuthContext';

export const SavedIdeasPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ideas, setIdeas] = useState<Idea[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const page = Number(searchParams.get('page')) || 1;
  const pageSize = 10;

  console.log('SavedIdeasPage rendered, user:', user ? 'authenticated' : 'not authenticated');

  useEffect(() => {
    console.log('SavedIdeasPage useEffect triggered, user:', user ? 'authenticated' : 'not authenticated');
    
    // Redirect to login if not authenticated
    if (!user) {
      console.log('User not authenticated, redirecting to login');
      sessionStorage.setItem('returnPath', window.location.pathname);
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        console.log('Fetching saved ideas, page:', page, 'pageSize:', pageSize);
        const response = await getSavedIdeas(page, pageSize);
        console.log('Saved ideas response:', response);
        setIdeas(response.items);
        setTotalPages(response.total_pages);
      } catch (error) {
        console.error('Failed to fetch saved ideas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, user, navigate]);

  const handlePageChange = (newPage: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', String(newPage));
    setSearchParams(newParams);
  };

  return (
    <div className="min-h-screen bg-[#F5F5F2]">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-12 text-center">Saved Ideas</h1>

        {/* Ideas Grid */}
        {loading ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <>
            {ideas.length === 0 ? (
              <div className="text-center py-12">
                <p className="text-xl text-gray-600 mb-6">You haven't saved any ideas yet.</p>
                <Button 
                  onClick={() => navigate('/ideas')}
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                >
                  Explore Ideas
                </Button>
              </div>
            ) : (
              <div className="grid gap-6 max-w-4xl mx-auto">
                {ideas.map((idea) => (
                  <div key={idea.id} className="bg-white rounded-lg shadow-sm">
                    <IdeaCard idea={idea} />
                  </div>
                ))}
              </div>
            )}

            {/* Pagination Controls */}
            {ideas.length > 0 && (
              <div className="flex justify-center items-center gap-2 mt-8">
                <Button
                  variant="secondary"
                  disabled={page === 1 || loading}
                  onClick={() => handlePageChange(page - 1)}
                  className="bg-white hover:bg-gray-100 text-gray-900 border-gray-200"
                >
                  Previous
                </Button>
                
                <span className="flex items-center px-4 text-gray-900">
                  Page {page} of {totalPages}
                </span>

                <Button
                  variant="secondary"
                  disabled={page === totalPages || loading}
                  onClick={() => handlePageChange(page + 1)}
                  className="bg-white hover:bg-gray-100 text-gray-900 border-gray-200"
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      
      {/* Decorative Elements */}
      <div className="fixed top-40 left-10 w-20 h-20 bg-gray-200 rounded-full blur-3xl opacity-20"></div>
      <div className="fixed top-60 right-10 w-32 h-32 bg-gray-300 rounded-full blur-3xl opacity-20"></div>
      <div className="fixed bottom-40 left-1/4 w-24 h-24 bg-gray-400 rounded-full blur-3xl opacity-20"></div>
    </div>
  );
}; 