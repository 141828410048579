import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { 
  BookmarkIcon, 
  Share2, 
  ArrowLeft,
  Quote,
  ChevronRight, 
  ChevronDown, 
  ChevronUp,
  Clock,
  DollarSign,
  Users,
  TrendingUp,
  Target,
  AlertTriangle,
  CheckCircle2,
  Lightbulb,
  ThumbsUp,
  ThumbsDown
} from 'lucide-react';
import { Button } from '../components/ui/button';
import { Card } from '../components/ui/card';
import { saveIdea, unsaveIdea, updateIdeaVotes, api } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { useToast } from '../components/ui/use-toast';
import { cn } from '../lib/utils';
import { CardHeader, CardTitle, CardContent } from '../components/ui/card';

/**
 * Formats a number with commas as thousands separators
 * @param num - The number to format
 * @returns Formatted number with commas
 */
const formatNumberWithCommas = (num: number | string): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

interface IdeaDetails {
  id: string;
  title: string;
  sourceEpisode: {
    id: string;
    title: string;
    timestamp: string;
  };
  industryTags: string[];
  shortDescription: string;
  quotes: {
    text: string;
    speaker: string;
    timestamp: string;
  }[];
  metrics: {
    marketSize: string;
    timeline: string;
    initialInvestment: string;
    teamSize: string;
    competition: string;
    marketTrend: string;
  };
  businessPlan: {
    overview: string;
    valueProposition: string[];
    targetMarket: string[];
    revenueModel: string[];
    challenges: string[];
    opportunities: string[];
  };
  implementation: {
    [key: string]: {
      title: string;
      timeline: string;
      tasks: string[];
    };
  };
  user_vote?: 'upvote' | 'downvote';
  upvotes: number;
  downvotes: number;
  is_saved: boolean;
}

interface Step {
  description: string;
}

const IdeaDetailsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [idea, setIdea] = useState<IdeaDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [isVoting, setIsVoting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [metricsExpanded, setMetricsExpanded] = useState<boolean>(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { ideaId } = useParams<{ ideaId: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toast } = useToast();
  
  // Add refs to track request status
  const pendingSaveProcessed = useRef(false);
  const saveRequestInProgress = useRef(false);

  // Check if scrolling is needed and scroll to active tab
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const isScrollable = container.scrollWidth > container.clientWidth;
      setShowScrollIndicator(isScrollable);
      
      // Scroll active tab into view
      const activeTabElement = container.querySelector(`[data-tab="${activeTab}"]`) as HTMLButtonElement;
      if (activeTabElement) {
        const containerRect = container.getBoundingClientRect();
        const activeTabRect = activeTabElement.getBoundingClientRect();
        
        if (activeTabRect.right > containerRect.right) {
          container.scrollLeft = activeTabElement.offsetLeft - container.clientWidth + activeTabElement.offsetWidth + 20;
        } else if (activeTabRect.left < containerRect.left) {
          container.scrollLeft = activeTabElement.offsetLeft - 20;
        }
      }
    }
  }, [activeTab]);
  
  // Auto-scroll to show Insights tab after loading
  useEffect(() => {
    // Only run the effect if we have the idea data and we're not loading
    if (loading || !idea) return;

    const timer = setTimeout(() => {
      const container = scrollContainerRef.current;
      if (!container) return;

      // Check if scrolling is actually needed
      const isScrollable = container.scrollWidth > container.clientWidth;
      if (!isScrollable) return;

      // Calculate scroll distance based on content
      const scrollDistance = Math.min(100, container.scrollWidth * 0.15);
      
      // Initial scroll to hint at more content
      requestAnimationFrame(() => {
        container.scrollTo({ 
          left: scrollDistance, 
          behavior: 'smooth' 
        });
        
        // Scroll back after user sees the hint
        setTimeout(() => {
          container.scrollTo({ 
            left: 0, 
            behavior: 'smooth' 
          });
        }, 800);
      });
    }, 500);
    
    return () => clearTimeout(timer);
  }, [loading, idea]);

  // Update the scroll container styles
  const scrollContainerStyles = {
    display: 'flex',
    overflowX: 'auto',
    scrollbarWidth: 'none' as const,
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  } as const;

  useEffect(() => {
    const fetchIdeaDetails = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/ideas/${ideaId}/details`);
        
        // Transform the API response to match our IdeaDetails interface
        const apiData = response.data;
        
        // Log the API response for debugging
        console.log('API Response:', apiData);
        console.log('Business Plan:', apiData.business_plan);
        console.log('Foundation Setup:', apiData.business_plan?.foundation_setup);
        console.log('Program Development:', apiData.business_plan?.program_development);
        
        const transformedData: IdeaDetails = {
          id: apiData.id,
          title: apiData.title,
          sourceEpisode: {
            id: apiData.episode.id,
            title: apiData.episode.title,
            timestamp: apiData.quotes[0]?.timestamp || ""
          },
          industryTags: [apiData.business_area],
          shortDescription: apiData.short_description,
          quotes: apiData.quotes || [],
          metrics: {
            marketSize: apiData.normalized_market_size_usd !== undefined 
              ? `$${formatNumberWithCommas(apiData.normalized_market_size_usd)}` 
              : apiData.business_plan?.market_size || "$0",
            timeline: apiData.business_plan?.timeline || "N/A",
            initialInvestment: apiData.business_plan?.initial_investment || "N/A",
            teamSize: apiData.business_plan?.team_size || "N/A",
            competition: apiData.business_plan?.competition_level || "Low",
            marketTrend: apiData.business_plan?.market_trend || "Stable"
          },
          businessPlan: {
            overview: apiData.business_plan?.detailed_description || apiData.short_description,
            valueProposition: apiData.business_plan?.value_propositions || [],
            targetMarket: apiData.business_plan?.target_market || [],
            revenueModel: apiData.business_plan?.revenue_model || [],
            challenges: apiData.business_plan?.challenges || [],
            opportunities: apiData.business_plan?.opportunities || []
          },
          implementation: {
            phase1: {
              title: "Foundation Setup",
              timeline: apiData.business_plan?.foundation_setup?.timeline || "N/A",
              tasks: Array.isArray(apiData.business_plan?.foundation_setup?.steps) 
                ? apiData.business_plan.foundation_setup.steps.map((step: Step) => step.description)
                : []
            },
            phase2: {
              title: "Program Development",
              timeline: apiData.business_plan?.program_development?.timeline || "N/A",
              tasks: Array.isArray(apiData.business_plan?.program_development?.steps) 
                ? apiData.business_plan.program_development.steps.map((step: Step) => step.description)
                : []
            }
          },
          user_vote: apiData.user_vote,
          upvotes: apiData.upvotes,
          downvotes: apiData.downvotes,
          is_saved: apiData.is_saved
        };
        
        setIdea(transformedData);
      } catch (error) {
        console.error('Error fetching idea details:', error);
        // Fallback to mock data if API call fails
        setIdea({
          id: ideaId || '',
          title: "Fintech and Healthtech Startup Incubation",
          sourceEpisode: {
            id: "1",
            title: "WTF is Venture Capital? Ft. Nikhil, Nithin, Rajan A.",
            timestamp: "23:45"
          },
          industryTags: ['Fintech', 'Healthcare', 'B2B'],
          shortDescription: "A comprehensive startup incubation program focused on the intersection of fintech and healthcare.",
          quotes: [
            {
              text: "The intersection of healthcare and fintech is largely unexplored in India.",
              speaker: "Nithin",
              timestamp: "23:45"
            }
          ],
          metrics: {
            marketSize: "$1000M",
            timeline: "12-18 months",
            initialInvestment: "$250K-500K",
            teamSize: "5-10 people",
            competition: "Medium",
            marketTrend: "Growing"
          },
          businessPlan: {
            overview: "A comprehensive startup incubation program focused on the intersection of fintech and healthcare.",
            valueProposition: [
              "End-to-end startup support system",
              "Access to regulatory expertise",
              "Industry-specific API infrastructure"
            ],
            targetMarket: [
              "Early-stage healthcare startups",
              "Fintech companies expanding into healthcare"
            ],
            revenueModel: [
              "Equity stake in incubated startups (5-10%)",
              "API usage fees"
            ],
            challenges: [
              "Complex regulatory environment",
              "Long sales cycles in healthcare"
            ],
            opportunities: [
              "Growing digital health adoption",
              "Government push for healthcare digitization"
            ]
          },
          implementation: {
            phase1: {
              title: "Foundation Setup",
              timeline: "Months 1-3",
              tasks: [
                "Legal entity establishment",
                "Core team hiring",
                "Initial partnerships"
              ]
            },
            phase2: {
              title: "Program Development",
              timeline: "Months 4-6",
              tasks: [
                "Curriculum development",
                "Mentor network building",
                "API infrastructure development"
              ]
            }
          },
          user_vote: undefined,
          upvotes: 0,
          downvotes: 0,
          is_saved: false
        });
      } finally {
        setLoading(false);
      }
    };

    fetchIdeaDetails();
  }, [ideaId]);

  // Handle pending save action after login
  useEffect(() => {
    const handlePendingSaveAction = async () => {
      if (user && idea && !pendingSaveProcessed.current) {
        const pendingSaveIdeaId = sessionStorage.getItem('pendingSaveIdeaId');
        if (pendingSaveIdeaId && pendingSaveIdeaId === idea.id) {
          // Mark as processed immediately to prevent duplicate processing
          pendingSaveProcessed.current = true;
          
          try {
            console.log(`Processing pending save for idea: ${pendingSaveIdeaId}`);
            setIsSaving(true);
            await saveIdea(idea.id);
            setIdea(prev => prev ? { ...prev, is_saved: true } : null);
            toast({
              title: 'Success',
              description: 'Idea saved successfully',
            });
          } catch (error) {
            console.error('Failed to save idea after login:', error);
            toast({
              title: 'Error',
              description: 'Failed to save idea. Please try again.',
              variant: 'destructive',
            });
          } finally {
            // Clear the pending save action
            sessionStorage.removeItem('pendingSaveIdeaId');
            setIsSaving(false);
          }
        }
      }
    };

    handlePendingSaveAction();
  }, [user, idea, toast]);

  const handleVote = async (type: 'upvote' | 'downvote') => {
    if (!user) {
      sessionStorage.setItem('returnPath', window.location.pathname);
      navigate('/login');
      return;
    }

    if (isVoting || !idea) return;

    setIsVoting(true);
    try {
      const updatedIdea = await updateIdeaVotes(idea.id, type);
      setIdea(prev => prev ? { ...prev, ...updatedIdea } : null);
    } catch (error) {
      console.error('Error voting:', error);
      toast({
        title: 'Error',
        description: 'Failed to vote. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsVoting(false);
    }
  };

  const handleSave = async () => {
    // Prevent duplicate save requests
    if (saveRequestInProgress.current) {
      console.log('Save request already in progress, ignoring duplicate request');
      return;
    }
    
    if (!user) {
      sessionStorage.setItem('returnPath', window.location.pathname);
      if (idea) {
        sessionStorage.setItem('pendingSaveIdeaId', idea.id);
      }
      navigate('/login');
      return;
    }

    if (isSaving || !idea) return;

    setIsSaving(true);
    saveRequestInProgress.current = true;
    
    try {
      if (idea.is_saved) {
        await unsaveIdea(idea.id);
        setIdea(prev => prev ? { ...prev, is_saved: false } : null);
        toast({
          title: 'Success',
          description: 'Idea removed from saved ideas',
        });
      } else {
        await saveIdea(idea.id);
        setIdea(prev => prev ? { ...prev, is_saved: true } : null);
        toast({
          title: 'Success',
          description: 'Idea saved successfully',
        });
      }
    } catch (error) {
      console.error('Error saving idea:', error);
      toast({
        title: 'Error',
        description: 'Failed to save idea. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsSaving(false);
      // Reset the request lock after a short delay to prevent immediate retries
      setTimeout(() => {
        saveRequestInProgress.current = false;
      }, 500);
    }
  };

  const handleShare = async () => {
    if (!idea) return;
    
    try {
      await navigator.share({
        title: idea.title,
        text: idea.shortDescription,
        url: window.location.href,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  if (loading || !idea) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      {/* Header */}
      <div className="mb-8">
        <Link to="/ideas" className="flex items-center text-gray-600 hover:text-blue-600 mb-4">
          <ArrowLeft size={20} className="mr-2" />
          Back to Ideas
        </Link>
        
        {/* Title, episode info, and desktop buttons */}
        <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between mb-4">
          <div>
            <h1 className="text-2xl lg:text-3xl font-bold mb-2">{idea.title}</h1>
            <p className="text-sm text-gray-600">From episode: {idea.sourceEpisode.title}</p>
          </div>
          
          {/* Desktop Save/Share Buttons - visible only on desktop */}
          <div className="hidden lg:flex items-center space-x-4 mt-2">
            <Button
              variant="outline"
              className={cn(
                "flex items-center",
                idea.is_saved ? "text-blue-600" : "text-gray-600"
              )}
              onClick={handleSave}
              disabled={isSaving}
            >
              <BookmarkIcon size={20} className="mr-2" />
              {idea.is_saved ? 'Saved' : 'Save'}
            </Button>
            <Button variant="outline" className="flex items-center" onClick={handleShare}>
              <Share2 size={20} className="mr-2" />
              Share
            </Button>
          </div>
        </div>
        
        {/* Voting Controls */}
        <div className="flex items-center mb-4">
          <button
            onClick={() => handleVote('upvote')}
            disabled={isVoting}
            className={`flex items-center space-x-2 mr-4 ${
              isVoting ? 'opacity-50 cursor-not-allowed' : 
              idea.user_vote === 'upvote' ? 'text-green-600' : 'text-gray-600 hover:text-green-600'
            } transition-colors`}
          >
            <ThumbsUp size={20} />
            <span className="font-medium">{idea.upvotes}</span>
          </button>

          <button
            onClick={() => handleVote('downvote')}
            disabled={isVoting}
            className={`flex items-center space-x-2 ${
              isVoting ? 'opacity-50 cursor-not-allowed' : 
              idea.user_vote === 'downvote' ? 'text-red-600' : 'text-gray-600 hover:text-red-600'
            } transition-colors`}
          >
            <ThumbsDown size={20} />
            <span className="font-medium">{idea.downvotes}</span>
          </button>
        </div>
      </div>

      {/* Industry Tags */}
      <div className="flex flex-wrap gap-2 mb-4">
        {idea.industryTags.map((tag) => (
          <span
            key={tag}
            className="bg-blue-50 text-blue-600 px-3 py-1 rounded-full text-sm font-medium"
          >
            {tag}
          </span>
        ))}
      </div>

      {/* Mobile Save and Share buttons - visible only on mobile */}
      <div className="flex lg:hidden items-center space-x-4 mb-8">
        <Button
          variant="outline"
          className={cn(
            "flex items-center",
            idea.is_saved ? "text-blue-600" : "text-gray-600"
          )}
          onClick={handleSave}
          disabled={isSaving}
        >
          <BookmarkIcon size={20} className="mr-2" />
          {idea.is_saved ? 'Saved' : 'Save'}
        </Button>
        <Button variant="outline" className="flex items-center" onClick={handleShare}>
          <Share2 size={20} className="mr-2" />
          Share
        </Button>
      </div>

      {/* Navigation Tabs */}
      <div className="border-b mb-8">
        <div className="relative">
          <div 
            ref={scrollContainerRef}
            className="flex overflow-x-auto scrollbar-hide relative py-2 px-1 no-scrollbar"
            style={{ scrollBehavior: 'smooth' }}
          >
            {[
              { id: 'overview', label: 'Overview' },
              { id: 'businessPlan', label: 'Business Plan' },
              { id: 'implementation', label: 'Implementation' },
              { id: 'insights', label: 'Insights & Quotes' }
            ].map((tab) => (
              <button
                key={tab.id}
                data-tab={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`py-3 px-4 whitespace-nowrap relative flex-shrink-0 transition-colors ${
                  activeTab === tab.id
                    ? 'text-blue-600 font-medium'
                    : 'text-gray-600 hover:text-blue-600'
                }`}
              >
                {tab.label}
                {activeTab === tab.id && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
                )}
              </button>
            ))}
          </div>
          
         
        </div>
      </div>

      {/* Insert Mobile Metrics Accordion (visible only on mobile) */}
      <div className="lg:hidden mb-6">
        <button 
          className="w-full flex justify-between items-center p-4 bg-gray-50 rounded-lg shadow-sm" 
          onClick={() => setMetricsExpanded(!metricsExpanded)}
        >
          <span className="text-lg font-medium">Key Metrics</span>
          {metricsExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        {metricsExpanded && (
          <div className="mt-2 border border-gray-200 rounded-lg p-4 bg-white">
            <div className="space-y-6">
              <div className="flex items-start">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                  <DollarSign size={24} className="text-emerald-500" />
                </div>
                <div className="ml-4 flex-grow">
                  <div className="flex flex-col">
                    <span className="text-gray-600 mb-1">Market Size</span>
                    <span className="text-lg font-medium">{idea.metrics.marketSize}</span>
                  </div>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                  <Clock size={24} className="text-amber-500" />
                </div>
                <div className="ml-4 flex-grow">
                  <div className="flex flex-col">
                    <span className="text-gray-600 mb-1">Timeline</span>
                    <span className="text-lg font-medium">{idea.metrics.timeline}</span>
                  </div>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                  <DollarSign size={24} className="text-purple-500" />
                </div>
                <div className="ml-4 flex-grow">
                  <div className="flex flex-col">
                    <span className="text-gray-600 mb-1">Investment</span>
                    <span className="text-lg font-medium">{idea.metrics.initialInvestment}</span>
                  </div>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                  <Users size={24} className="text-blue-500" />
                </div>
                <div className="ml-4 flex-grow">
                  <div className="flex flex-col">
                    <span className="text-gray-600 mb-1">Team Size</span>
                    <span className="text-lg font-medium">{idea.metrics.teamSize}</span>
                  </div>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                  <Target size={24} className="text-red-500" />
                </div>
                <div className="ml-4 flex-grow">
                  <div className="flex flex-col">
                    <span className="text-gray-600 mb-1">Competition</span>
                    <span className="text-lg font-medium">{idea.metrics.competition}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Content Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2">
          {activeTab === 'overview' && (
            <div className="space-y-8">
              <div>
                <h2 className="text-xl font-semibold mb-4">Overview</h2>
                <p className="text-gray-600">{idea.businessPlan.overview}</p>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-3">Value Proposition</h3>
                <div className="space-y-2">
                  {idea.businessPlan.valueProposition.map((value, index) => (
                    <div key={index} className="flex items-start">
                      <CheckCircle2 size={20} className="text-green-500 mr-2 mt-1" />
                      <span>{value}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-semibold mb-3">Challenges</h3>
                  <div className="space-y-2">
                    {idea.businessPlan.challenges.map((challenge, index) => (
                      <div key={index} className="flex items-start">
                        <AlertTriangle size={20} className="text-amber-500 mr-2 mt-1" />
                        <span>{challenge}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-3">Opportunities</h3>
                  <div className="space-y-2">
                    {idea.businessPlan.opportunities.map((opportunity, index) => (
                      <div key={index} className="flex items-start">
                        <Lightbulb size={20} className="text-blue-500 mr-2 mt-1" />
                        <span>{opportunity}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'businessPlan' && (
            <div className="space-y-8">
              <div>
                <h2 className="text-xl font-semibold mb-4">Target Market</h2>
                <div className="space-y-4">
                  {idea.businessPlan.targetMarket.map((target, index) => (
                    <div key={index} className="flex items-start">
                      <Target size={20} className="text-blue-500 mr-2 mt-1" />
                      <span>{target}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-4">Revenue Model</h2>
                <div className="space-y-4">
                  {idea.businessPlan.revenueModel.map((revenue, index) => (
                    <div key={index} className="flex items-start">
                      <DollarSign size={20} className="text-green-500 mr-2 mt-1" />
                      <span>{revenue}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'implementation' && (
            <div className="space-y-8">
              {(() => {
                // For debugging only
                console.log('Implementation data:', idea.implementation);
                return null;
              })()}
              {Object.entries(idea.implementation || {}).length > 0 ? (
                Object.values(idea.implementation || {}).map((phase) => {
                  // For debugging only
                  console.log('Phase data:', phase);
                  if (!phase || typeof phase !== 'object') {
                    return null;
                  }
                  return (
                    <Card key={phase.title || 'unknown'} className="p-6">
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">{phase.title || 'Unnamed Phase'}</h3>
                        <span className="text-gray-600">{phase.timeline || 'No timeline'}</span>
                      </div>
                      <div className="space-y-3">
                        {Array.isArray(phase.tasks) && phase.tasks.length > 0 ? (
                          phase.tasks.map((task, index) => (
                            <div key={index} className="flex items-center">
                              <div className="w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3">
                                {index + 1}
                              </div>
                              <span>{task}</span>
                            </div>
                          ))
                        ) : (
                          <div className="text-gray-500">No tasks available for this phase</div>
                        )}
                      </div>
                    </Card>
                  );
                })
              ) : (
                <div className="text-center py-8 text-gray-500">
                  <p>No implementation details available for this idea.</p>
                </div>
              )}
            </div>
          )}

          {activeTab === 'insights' && (
            <div className="space-y-8">
              <div>
                <h2 className="text-xl font-semibold mb-4">Expert Quotes</h2>
                <div className="space-y-6">
                  {idea.quotes.map((quote, index) => (
                    <Card key={index} className="p-6">
                      <div className="flex items-start">
                        <Quote size={24} className="text-blue-600 mr-4 mt-1" />
                        <div>
                          <p className="text-lg mb-2">{quote.text}</p>
                          <div className="flex items-center text-gray-600">
                            <span className="font-medium">{quote.speaker}</span>
                            <span className="mx-2">•</span>
                            <span>{quote.timestamp}</span>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Sidebar (hidden on mobile) */}
        <div className="hidden lg:block space-y-6">
          <Card className="border border-gray-200 shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-lg font-medium">Key Metrics</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                    <DollarSign size={24} className="text-emerald-500" />
                  </div>
                  <div className="ml-4 flex-grow">
                    <div className="flex flex-col">
                      <span className="text-gray-600 mb-1">Market Size</span>
                      <span className="text-lg font-medium">{idea.metrics.marketSize}</span>
                    </div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                    <Clock size={24} className="text-amber-500" />
                  </div>
                  <div className="ml-4 flex-grow">
                    <div className="flex flex-col">
                      <span className="text-gray-600 mb-1">Timeline</span>
                      <span className="text-lg font-medium">{idea.metrics.timeline}</span>
                    </div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                    <DollarSign size={24} className="text-purple-500" />
                  </div>
                  <div className="ml-4 flex-grow">
                    <div className="flex flex-col">
                      <span className="text-gray-600 mb-1">Initial Investment</span>
                      <span className="text-lg font-medium">{idea.metrics.initialInvestment}</span>
                    </div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                    <Users size={24} className="text-blue-500" />
                  </div>
                  <div className="ml-4 flex-grow">
                    <div className="flex flex-col">
                      <span className="text-gray-600 mb-1">Team Size</span>
                      <span className="text-lg font-medium">{idea.metrics.teamSize}</span>
                    </div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                    <Target size={24} className="text-red-500" />
                  </div>
                  <div className="ml-4 flex-grow">
                    <div className="flex flex-col">
                      <span className="text-gray-600 mb-1">Competition</span>
                      <span className="text-lg font-medium">{idea.metrics.competition}</span>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="bg-blue-50 p-6">
            <h3 className="font-semibold mb-4">Market Trend</h3>
            <div className="flex items-center text-blue-600">
              <TrendingUp size={20} className="mr-2" />
              <span>{idea.metrics.marketTrend}</span>
            </div>
          </Card>
        </div>
      </div>
      {/* Mobile Market Trend Card: visible only on mobile */}
      <div className="block lg:hidden mt-6">
        <Card className="bg-blue-50 p-6">
          <h3 className="font-semibold mb-4">Market Trend</h3>
          <div className="flex items-center text-blue-600">
            <TrendingUp size={20} className="mr-2" />
            <span>{idea.metrics.marketTrend}</span>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default IdeaDetailsPage; 