import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { getPodcasts, logout, subscribeToNewsletter } from './services/api';
import { Podcast } from './types/index';
import { PodcastCard } from './components/podcast/PodcastCard';
import { LoginForm } from './components/auth/LoginForm';
import { PodcastPage } from './pages/PodcastPage';
import { PodcastsPage } from './pages/PodcastsPage';
import { ExploreIdeasPage } from './pages/ExploreIdeasPage';
import { SavedIdeasPage } from './pages/SavedIdeasPage';
import IdeaDetailsPage from './pages/IdeaDetailsPage';
import AboutPage from './pages/AboutPage';
import TeamPage from './pages/TeamPage';
import './styles/animations.css';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "./components/ui/carousel"
import Features from "./components/ui/Features"
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { Toaster } from './components/ui/toaster';
import { FeaturedIdeasCarousel } from './components/FeaturedIdeasCarousel';

function AppContent() {
  const [podcasts, setPodcasts] = useState<Podcast[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user, signOut } = useAuth();
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getPodcasts();
        setPodcasts(data);
      } catch (err) {
        const errorMessage = err instanceof Error 
          ? err.message 
          : 'Failed to fetch podcasts. Please try again later.';
        setError(`Error: ${errorMessage}`);
        console.error('Error fetching podcasts:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPodcasts();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      // Redirect to home page after successful logout
      window.location.href = '/';
    } catch (error) {
      console.error('Logout failed:', error);
      // Even if there's an error, we should still redirect to ensure the user is logged out
      window.location.href = '/';
    }
  };

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) return;
    
    try {
      setSubscriptionStatus('idle'); // Reset status
      
      // Call the API to subscribe the user
      const response = await subscribeToNewsletter(email, 'footer');
      
      // Handle success
      setSubscriptionStatus('success');
      setEmail('');
      
      // Reset status after 3 seconds
      setTimeout(() => {
        setSubscriptionStatus('idle');
      }, 3000);
    } catch (error) {
      console.error('Subscription failed:', error);
      setSubscriptionStatus('error');
      
      // Reset error status after 3 seconds
      setTimeout(() => {
        setSubscriptionStatus('idle');
      }, 3000);
    }
  };

  return (
    <div className="min-h-screen bg-[#F5F5F2]">
      <nav className="px-8 py-6 border-b border-gray-200 bg-[#F5F5F2] shadow-sm sticky top-0 z-50">
        <div className="max-w-[1400px] mx-auto">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link to="/" className="text-4xl font-quincycf-bold tracking-tight">
                podthinker
                <span className="inline-block font-sans w-[0.5em] animate-blink">_</span>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/ideas" className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors">
                All Ideas
              </Link>
              <Link to="/podcasts" className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors">
                All Podcasts
              </Link>
              {user && (
                <Link 
                  to="/ideas/saved" 
                  className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                  onClick={() => {
                    console.log('Saved Ideas link clicked, navigating to /ideas/saved');
                  }}
                >
                  Saved Ideas
                </Link>
              )}
              
              <Link to="/about" className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors">
                About
              </Link>
              <Link to="/team" className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors">
                Team
              </Link>
              {user ? (
                <button
                  onClick={handleLogout}
                  className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                >
                  Logout
                </button>
              ) : (
                <Link to="/login" className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors">
                  Login
                </Link>
              )}
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <button 
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-gray-600 hover:text-gray-900 focus:outline-none"
                aria-label="Toggle menu"
              >
                <svg 
                  className="h-6 w-6" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  {mobileMenuOpen ? (
                    // X icon when menu is open
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M6 18L18 6M6 6l12 12" 
                    />
                  ) : (
                    // Hamburger icon when menu is closed
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M4 6h16M4 12h16M4 18h16" 
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>

          {/* Mobile Navigation */}
          {mobileMenuOpen && (
            <div className="md:hidden mt-4 pb-4 border-t border-gray-200 pt-4 space-y-2">
              <Link 
                to="/ideas" 
                className="block text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                onClick={() => setMobileMenuOpen(false)}
              >
                All Ideas
              </Link>
              <Link 
                to="/podcasts" 
                className="block text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                onClick={() => setMobileMenuOpen(false)}
              >
                All Podcasts
              </Link>
              {user && (
                <Link 
                  to="/ideas/saved" 
                  className="block text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                  onClick={() => {
                    console.log('Saved Ideas link clicked, navigating to /ideas/saved');
                    setMobileMenuOpen(false);
                  }}
                >
                  Saved Ideas
                </Link>
              )}
              <Link 
                to="/about" 
                className="block text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                onClick={() => setMobileMenuOpen(false)}
              >
                About
              </Link>
              <Link 
                to="/team" 
                className="block text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                onClick={() => setMobileMenuOpen(false)}
              >
                Team
              </Link>
              {user ? (
                <button
                  onClick={() => {
                    handleLogout();
                    setMobileMenuOpen(false);
                  }}
                  className="block w-full text-left text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                >
                  Logout
                </button>
              ) : (
                <Link 
                  to="/login" 
                  className="block text-gray-600 hover:text-gray-900 px-3 py-2 text-sm transition-colors"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Login
                </Link>
              )}
            </div>
          )}
        </div>
      </nav>

      <Routes>
        <Route 
          path="/login" 
          element={
            user ? (
              (() => {
                const params = new URLSearchParams(window.location.search);
                const returnTo = params.get('returnTo') || sessionStorage.getItem('returnPath') || '/';
                
                // Clear the stored path after using it
                sessionStorage.removeItem('returnPath');
                
                console.log('Login redirect:', {
                  user: !!user,
                  returnTo,
                  searchParams: Object.fromEntries(params.entries()),
                  sessionStorage: sessionStorage.getItem('returnPath')
                });
                
                return <Navigate to={returnTo} replace />;
              })()
            ) : (
              <LoginForm />
            )
          } 
        />
        
        <Route path="/" element={
          <>
            {loading ? (
              <div className="min-h-screen flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
              </div>
            ) : error ? (
              <div className="min-h-screen flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-md text-center">
                  <p className="text-gray-700 mb-4">{error}</p>
                  <button
                    onClick={() => window.location.reload()}
                    className="bg-gray-900 text-white px-4 py-2 rounded hover:bg-gray-800 transition-colors"
                  >
                    Retry
                  </button>
                </div>
              </div>
            ) : (
              <>
                <section className="px-8 py-20">
                  <div className="max-w-[1400px] mx-auto">
                    <div className="max-w-3xl mb-20 mx-auto text-center">
                      <h1 className="text-6xl font-quincycf-bold tracking-tight mb-8">
                        Get business ideas and insights
                        from top business podcasts using AI
                      </h1>
                      <p className="text-xl text-gray-600 mb-8">
                      PodThinker uses AI to extract valuable insights and business opportunities from the world's best business podcasts. Over 1,200+ ideas extracted so far.
                      </p>
                     
                      <div className="flex items-center justify-center gap-6 mb-12">
                        <a
                          href="/ideas"
                          className="inline-flex items-center px-6 py-3 rounded-full bg-black text-white hover:opacity-90"
                        >
                          Explore Ideas →
                        </a>
                        <a
                          href="/podcasts"
                          className="inline-flex items-center px-6 py-3 rounded-full bg-white text-black hover:opacity-90"
                        >
                          Browse Podcasts →
                        </a>
                      </div>
                    </div>
                    
                    {/* Featured Ideas Carousel */}
                    <FeaturedIdeasCarousel className="mb-20" />
                    
                    {/* Latest Podcasts Carousel - Moved to Features component */}
                    
                    <div className="flex items-center mt-6 space-x-1 text-sm text-gray-500">
                    </div>

                    <Features podcasts={podcasts} isLoading={loading} />

                    <div className="mt-20 text-center">
                      <div className="flex justify-center gap-4 mb-12">
                        <a
                          href="/podcasts"
                          className="inline-flex items-center px-6 py-3 rounded-full bg-black text-white hover:opacity-90 transition-colors"
                        >
                          Explore Podcasts
                        </a>
                        <a
                          href="/ideas"
                          className="inline-flex items-center px-6 py-3 rounded-full border-2 border-black text-black hover:bg-black hover:text-white transition-colors"
                        >
                          Explore Ideas
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
          </>
        } />
        
        <Route path="/podcasts" element={<PodcastsPage />} />
        <Route path="/podcasts/:nameOrId" element={<PodcastPage />} />
        <Route path="/ideas" element={<ExploreIdeasPage />} />
        <Route path="/ideas/saved" element={<SavedIdeasPage />} />
        <Route path="/ideas/:ideaId" element={<IdeaDetailsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/team" element={<TeamPage />} />
      </Routes>
      
      {/* Stay Updated Section */}
      <section className="py-16 bg-gradient-to-b from-white to-[#F5F5F2] relative overflow-hidden">
        <div className="absolute inset-0 overflow-hidden opacity-5 pointer-events-none">
          <div className="absolute -top-24 -right-24 w-96 h-96 bg-black rounded-full"></div>
          <div className="absolute -bottom-24 -left-24 w-96 h-96 bg-black rounded-full"></div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 text-center relative z-10">
          <div className="mb-8 flex justify-center">
            <div className="inline-flex items-center gap-2 px-3 py-1 text-sm font-medium text-gray-500 border-b border-dashed border-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-400">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              JOIN THE COMMUNITY
            </div>
          </div>
          
          <h2 className="text-[2.75rem] font-quincycf-bold mb-6 tracking-tight">Stay in the Loop</h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Get notified about new podcasts, business ideas, and features. 
            No spam, just pure podcast-to-business inspiration.
          </p>
          
          <div className="max-w-md mx-auto mb-12">
            <form onSubmit={handleSubscribe} className="flex flex-col sm:flex-row gap-3">
              <input 
                type="email" 
                placeholder="Enter your email" 
                className="flex-1 px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-black"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button 
                type="submit"
                className="px-6 py-3 rounded-full bg-black text-white hover:opacity-90 transition-colors"
              >
                Subscribe
              </button>
            </form>
            
            {subscriptionStatus === 'success' && (
              <p className="text-sm text-green-600 mt-3 font-medium">
                Thanks for subscribing! We'll be in touch soon.
              </p>
            )}
            
            {subscriptionStatus === 'error' && (
              <p className="text-sm text-red-600 mt-3 font-medium">
                Something went wrong. Please try again.
              </p>
            )}
            
            {subscriptionStatus === 'idle' && (
              <p className="text-sm text-gray-500 mt-3">
                By subscribing, you agree to our privacy policy. Unsubscribe anytime.
              </p>
            )}
          </div>
          
          <div className="flex flex-wrap justify-center gap-8 mb-12">
            <div className="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-700">
                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                <path d="m9 12 2 2 4-4"></path>
              </svg>
              <span className="text-gray-700">Weekly Updates</span>
            </div>
            <div className="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-700">
                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                <path d="m9 12 2 2 4-4"></path>
              </svg>
              <span className="text-gray-700">Exclusive Content</span>
            </div>
            <div className="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-700">
                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                <path d="m9 12 2 2 4-4"></path>
              </svg>
              <span className="text-gray-700">Early Access</span>
            </div>
          </div>
          
          <div className="border-t border-gray-200 pt-8">
            <h3 className="text-lg font-semibold mb-4">Connect With Us</h3>
            <div className="flex justify-center gap-6">
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black transition-colors">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                </svg>
              </a>
              {/* TODO: Add LinkedIn */}
              {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black transition-colors">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                  <rect x="2" y="9" width="4" height="12"></rect>
                  <circle cx="4" cy="4" r="2"></circle>
                </svg>
              </a>
              {/* TODO: Add GitHub */}
              {/* <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black transition-colors">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                </svg>
              </a>  */}
            </div>
          </div>
        </div>
      </section>
      
      <footer className="py-8 text-center text-gray-600 text-sm border-t border-gray-200 bg-[#F5F5F2] shadow-[0_-1px_2px_0_rgba(0,0,0,0.05)]">
        Made with love ❤️ and crippling procrastination by Omkar Rajmane
      </footer>
      <Toaster />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
