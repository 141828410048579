import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Idea } from '../../types';
import { updateIdeaVotes, saveIdea, unsaveIdea } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../ui/use-toast';
import { 
  BookmarkIcon, 
  Share2, 
  ThumbsUp, 
  ThumbsDown,
  ChevronRight
} from 'lucide-react';
import { Button } from '../ui/button';
import { cn } from '../../lib/utils';

interface IdeaCardProps {
  idea: Idea;
  onSaveStateChange?: (ideaId: string, isSaved: boolean) => void;
}

const MAX_SHORT_DESC_LENGTH = 150;
const MAX_DETAILED_DESC_LENGTH = 300;

/**
 * Formats a number with commas as thousands separators
 * @param num - The number to format
 * @returns Formatted number with commas
 */
const formatNumberWithCommas = (num: number | string | null | undefined): string => {
  if (num === null || num === undefined) {
    return "0";
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Formats the market size based on the currency parameter
 * @param marketSize - The market size value
 * @param currency - The currency string (optional)
 * @returns Formatted market size with appropriate currency symbol and suffix
 */
const formatMarketSize = (marketSize: number | null | undefined, currency?: string): string => {
  if (marketSize === null || marketSize === undefined) {
    marketSize = 0;
  }
  
  // Round the market size to remove decimal points
  marketSize = Math.round(marketSize);
  
  if (!currency) {
    // Default to USD if no currency is provided
    return `$${formatNumberWithCommas(marketSize)}`;
  }

  const currencyLower = currency.toLowerCase();
  
  if (currencyLower.includes('inr')) {
    // For Indian Rupee, convert to crores (1 crore = 10 million)
    const croreValue = marketSize / 10;
    // Round to the nearest whole number
    return `₹${formatNumberWithCommas(Math.round(croreValue))} crore`;
  } else if (currencyLower.includes('usd')) {
    // For US Dollar
    return `$${formatNumberWithCommas(marketSize)}`;
  } else {
    // For any other currency, use the first 3 characters as the symbol
    // and "M" as the suffix
    const symbol = currency.substring(0, 3).toUpperCase();
    return `${symbol} ${formatNumberWithCommas(marketSize)}`;
  }
};

export const IdeaCard = ({ idea: initialIdea, onSaveStateChange }: IdeaCardProps) => {
  const [idea, setIdea] = useState(initialIdea);
  const [isVoting, setIsVoting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showFullShortDesc, setShowFullShortDesc] = useState(false);
  const [showFullDetailedDesc, setShowFullDetailedDesc] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toast } = useToast();

  // Add a ref to track if a save request is in progress
  const saveRequestInProgress = useRef(false);

  // Update local state when the parent component passes a new idea prop
  useEffect(() => {
    setIdea(initialIdea);
  }, [initialIdea]);

  const handleVote = async (type: 'upvote' | 'downvote') => {
    if (isVoting) return;
    
    if (!user) {
      sessionStorage.setItem('returnPath', window.location.pathname + window.location.search);
      navigate('/login');
      return;
    }
    
    setIsVoting(true);
    try {
      const updatedIdea = await updateIdeaVotes(idea.id, type);
      setIdea(updatedIdea);
    } catch (error) {
      console.error('Failed to update vote:', error);
      toast({
        title: 'Error',
        description: 'Failed to vote. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsVoting(false);
    }
  };

  const handleSave = async () => {
    // Prevent duplicate save requests
    if (saveRequestInProgress.current) {
      console.log('Save request already in progress, ignoring duplicate request');
      return;
    }

    if (!user) {
      // Store both the return path and the idea ID to save after login
      sessionStorage.setItem('returnPath', window.location.pathname + window.location.search);
      sessionStorage.setItem('pendingSaveIdeaId', idea.id);
      navigate('/login');
      return;
    }

    if (isSaving) return;

    setIsSaving(true);
    saveRequestInProgress.current = true;
    
    try {
      if (idea.is_saved) {
        await unsaveIdea(idea.id);
        setIdea(prev => ({ ...prev, is_saved: false }));
        // Notify parent component about the state change
        onSaveStateChange?.(idea.id, false);
      } else {
        await saveIdea(idea.id);
        setIdea(prev => ({ ...prev, is_saved: true }));
        // Notify parent component about the state change
        onSaveStateChange?.(idea.id, true);
        
        // Show the save success animation
        setShowSaveSuccess(true);
        setTimeout(() => setShowSaveSuccess(false), 1500);
      }
    } catch (error) {
      console.error('Failed to save idea:', error);
      toast({
        title: 'Error',
        description: 'Failed to save idea. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsSaving(false);
      // Reset the request lock after a short delay to prevent immediate retries
      setTimeout(() => {
        saveRequestInProgress.current = false;
      }, 500);
    }
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: idea.title,
        text: idea.short_description,
        url: window.location.href,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength).trim() + '...';
  };

  const renderDescription = (
    text: string,
    maxLength: number,
    isExpanded: boolean,
    onToggle: () => void
  ) => {
    const shouldTruncate = text.length > maxLength;
    const displayText = isExpanded ? text : truncateText(text, maxLength);

    return (
      <div className="relative">
        <p className="text-gray-600">
          {displayText}
        </p>
        {shouldTruncate && (
          <button
            onClick={onToggle}
            className="mt-2 text-blue-600 hover:text-blue-700 text-sm font-medium transition-colors"
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 transition-all duration-200 hover:shadow-xl">
      {/* Title and Action Buttons */}
      <div className="flex flex-wrap justify-between items-start mb-4 gap-2">
        <h3 className="text-lg sm:text-xl font-semibold text-gray-900 flex-grow">{idea.title}</h3>
        <div className="flex gap-2 text-gray-600 shrink-0">
          <Button
            variant="ghost"
            size="icon"
            onClick={handleShare}
            className="h-8 w-8 hover:text-blue-600"
          >
            <Share2 size={20} />
          </Button>
          <Button 
            variant="ghost" 
            size="icon" 
            onClick={handleSave} 
            disabled={isSaving}
            className={cn(
              "relative",
              idea.is_saved ? "text-primary" : "text-muted-foreground hover:text-primary"
            )}
          >
            <BookmarkIcon className={cn(
              "h-5 w-5 transition-all",
              idea.is_saved ? "fill-current" : "",
              showSaveSuccess && !idea.is_saved ? "scale-125 text-green-500" : ""
            )} />
            {showSaveSuccess && (
              <span className="absolute -top-1 -right-1 flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
              </span>
            )}
          </Button>
        </div>
      </div>

      {/* Short Description */}
      <div className="mb-6">
        {renderDescription(
          idea.short_description,
          MAX_SHORT_DESC_LENGTH,
          showFullShortDesc,
          () => setShowFullShortDesc(!showFullShortDesc)
        )}
      </div>

      {/* Metrics Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
          <div className="text-sm text-gray-500 mb-1">Market Size</div>
          <div className="text-base sm:text-lg font-semibold text-gray-900">
            {idea.normalized_market_size_usd !== undefined && idea.normalized_market_size_usd !== null
              ? `$${formatNumberWithCommas(idea.normalized_market_size_usd)}` 
              : (idea.market_size !== undefined 
                  ? formatMarketSize(idea.market_size, idea.currency)
                  : "$0")}
          </div>
        </div>
        <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
          <div className="text-sm text-gray-500 mb-1">Timeline</div>
          <div className="text-base sm:text-lg font-semibold text-gray-900">{idea.timeline || "12-18 months"}</div>
        </div>
      </div>

      {/* Detailed Description */}
      <div className="mb-6">
        {renderDescription(
          idea.detailed_description,
          MAX_DETAILED_DESC_LENGTH,
          showFullDetailedDesc,
          () => setShowFullDetailedDesc(!showFullDetailedDesc)
        )}
      </div>

      {/* Tags */}
      <div className="flex flex-wrap gap-2 mb-6">
        {idea.business_area.split(',').map((area, index) => (
          <span 
            key={index}
            className={`px-3 py-1 rounded-full text-sm font-medium ${
              area.trim().toLowerCase() === 'fintech' 
                ? 'bg-blue-50 text-blue-600'
                : area.trim().toLowerCase() === 'healthcare'
                ? 'bg-green-50 text-green-600'
                : 'bg-purple-50 text-purple-600'
            }`}
          >
            {area.trim()}
          </span>
        ))}
      </div>

      {/* Bottom Section */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        {/* Voting Controls */}
        <div className="flex items-center space-x-6">
          <button
            onClick={() => handleVote('upvote')}
            disabled={isVoting}
            className={`flex items-center space-x-2 ${
              isVoting ? 'opacity-50 cursor-not-allowed' : 
              idea.user_vote === 'upvote' ? 'text-green-600' : 'text-gray-600 hover:text-green-600'
            } transition-colors`}
          >
            <ThumbsUp size={20} />
            <span className="font-medium">{idea.upvotes}</span>
          </button>

          <button
            onClick={() => handleVote('downvote')}
            disabled={isVoting}
            className={`flex items-center space-x-2 ${
              isVoting ? 'opacity-50 cursor-not-allowed' : 
              idea.user_vote === 'downvote' ? 'text-red-600' : 'text-gray-600 hover:text-red-600'
            } transition-colors`}
          >
            <ThumbsDown size={20} />
            <span className="font-medium">{idea.downvotes}</span>
          </button>
        </div>

        {/* Explore Business Plan Link */}
        <button 
          className="flex items-center text-blue-600 hover:text-blue-700 font-medium transition-colors"
          onClick={() => navigate(`/ideas/${idea.id}`)}
        >
          Explore business plan
          <ChevronRight size={20} className="ml-1" />
        </button>
      </div>
    </div>
  );
}; 