import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Podcast, Episode } from '../types/index';
import { getPodcast, getEpisodes } from '../services/api';
import { EpisodeModal } from '../components/episode/EpisodeModal';
import { getPodcastIdFromName } from '../constants/podcastMapping';

/**
 * @param num The number to format
 * @returns Formatted number string with K for thousands and M for millions
 */
const formatSubscriberCount = (num: number): string => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    return num.toString();
  }
};

const hasGuestName = (episode: Episode): episode is Episode & { guest_name: string } => {
  return typeof episode.guest_name === 'string';
};

export const PodcastPage = () => {
  const { nameOrId } = useParams<{ nameOrId: string }>();
  const [podcast, setPodcast] = useState<Podcast | null>(null);
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedEpisode, setSelectedEpisode] = useState<Episode | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!nameOrId) return;
      
      // Convert the name or ID from the URL to the actual podcast ID
      const podcastId = getPodcastIdFromName(nameOrId);
      
      try {
        const [podcastData, episodesData] = await Promise.all([
          getPodcast(podcastId),
          getEpisodes(podcastId)
        ]);
        setPodcast(podcastData);
        setEpisodes(episodesData);
      } catch (error) {
        console.error('Failed to fetch podcast data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [nameOrId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-800"></div>
      </div>
    );
  }

  if (!podcast) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <h1 className="text-2xl text-gray-800">Podcast not found</h1>
      </div>
    );
  }

  const channelImageUrl = `/images/channels/${podcast.id}.jpg`;

  return (
    <div className="min-h-screen bg-[#F5F5F2] py-6 sm:py-8 md:py-12">
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 md:p-8 mb-8 md:mb-12">
          <div className="max-w-5xl mx-auto">
            {/* Podcast header - stack on mobile, side by side on larger screens */}
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 items-center sm:items-start mb-6 sm:mb-8">
              <img 
                src={channelImageUrl}
                alt={`${podcast.channel_name} channel`}
                className="w-32 h-32 sm:w-36 sm:h-36 md:w-40 md:h-40 rounded-full object-cover flex-shrink-0"
                onError={(e) => {
                  // If jpg fails, try png
                  const imgElement = e.target as HTMLImageElement;
                  if (imgElement.src.endsWith('.jpg')) {
                    imgElement.src = `/images/channels/${podcast.id}.png`;
                  } else {
                    // If both jpg and png fail, remove the image
                    imgElement.style.display = 'none';
                  }
                }}
              />
              <div className="flex-1 text-center sm:text-left">
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">{podcast.channel_name}</h1>
                <div className="flex flex-wrap justify-center sm:justify-start items-center gap-2 text-gray-600 mb-4">
                  <span className="font-medium">@{podcast.channel_name.toLowerCase().replace(/\s+/g, '')}</span>
                  <span className="text-sm hidden sm:inline">•</span>
                  <span>{podcast.subscriber_count ? `${formatSubscriberCount(podcast.subscriber_count)} subscribers` : 'No subscribers'}</span>
                  <span className="text-sm hidden sm:inline">•</span>
                  <span>{episodes.length} episodes</span>
                </div>
                <p className="text-gray-600 text-sm mb-4 max-w-prose">{podcast.description}</p>
                <a
                  href={podcast.channel_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 sm:px-6 py-2 sm:py-2.5 bg-gray-900 text-white rounded-full hover:bg-gray-800 transition-colors font-medium text-sm"
                >
                  Visit Channel
                </a>
              </div>
            </div>
            
            <div className="border-t border-gray-200 pt-6 sm:pt-8">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4 sm:mb-6">Episodes</h2>
              {/* Responsive grid - 1 column on mobile, 2 on tablet, 3 on desktop */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
                {episodes.map((episode) => (
                  <div
                    key={episode.id}
                    className="bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer transform hover:-translate-y-1"
                    onClick={() => setSelectedEpisode(episode)}
                  >
                    {episode.thumbnail_url && (
                      <div className="relative h-40 sm:h-44 md:h-48 overflow-hidden rounded-t-xl">
                        <img
                          src={episode.thumbnail_url}
                          alt={episode.title}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                    <div className="p-4 sm:p-5 md:p-6">
                      <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2 sm:mb-3 line-clamp-2">
                        {episode.title}
                      </h3>
                      {hasGuestName(episode) && (
                        <p className="text-blue-600 font-medium mb-2 sm:mb-3 text-sm sm:text-base">
                          Guest: {episode.guest_name}
                        </p>
                      )}
                      <p className="text-gray-600 text-sm sm:text-base line-clamp-3">{episode.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {selectedEpisode && (
          <EpisodeModal
            episode={selectedEpisode}
            onClose={() => setSelectedEpisode(null)}
          />
        )}
      </div>
    </div>
  );
}; 