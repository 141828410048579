"use client"

import { useEffect, useRef } from "react"

interface ScrollImageProps {
  src: string
  alt: string
  className?: string
  width?: number
  height?: number
}

export default function ScrollImage({ src, alt, className = "", width = 350, height = 250 }: ScrollImageProps) {
  const imageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("translate-y-0", "opacity-100")
            entry.target.classList.remove("translate-y-10", "opacity-0")
          }
        })
      },
      {
        threshold: 0.1,
      },
    )

    if (imageRef.current) {
      observer.observe(imageRef.current)
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current)
      }
    }
  }, [])

  return (
    <div ref={imageRef} className={`transition-all duration-700 ease-out translate-y-10 opacity-0 ${className}`}>
      <img 
        src={src || "/placeholder.svg"} 
        alt={alt} 
        width={width}
        height={height}
        className="rounded-lg object-contain mt-8" 
      />
    </div>
  )
}

