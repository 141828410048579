import { LightBulbIcon, ChartBarIcon, BoltIcon } from '@heroicons/react/24/outline';
import { SmileIcon, FrownIcon, Lightbulb } from "lucide-react";
import { useEffect, useState, useRef, useCallback } from 'react';
import ScrollImage from "./ScrollImage";
import reality from '../../assets/reality.svg';
import expectation from '../../assets/expectation.svg';
import sol_meme from '../../assets/sol_meme.jpg';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../ui/carousel";
import type { CarouselApi } from "../ui/carousel";
import { PodcastCard } from '../podcast/PodcastCard';
import { useAuth } from '../../contexts/AuthContext';
import { Podcast } from '../../types/index';

interface FeaturesProps {
  podcasts?: Podcast[];
  isLoading?: boolean;
}

const Features = ({ podcasts = [], isLoading = false }: FeaturesProps) => {
  const { user } = useAuth();
  const [podcastCarouselApi, setPodcastCarouselApi] = useState<CarouselApi | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const autoRotationIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Function to check if screen is small
  const checkScreenSize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < 768); // md breakpoint in Tailwind
  }, []);

  // Set up auto-rotation for small screens
  useEffect(() => {
    // Initial check
    checkScreenSize();

    // Add event listener for resize
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
      // Clear any existing interval when component unmounts
      if (autoRotationIntervalRef.current) {
        clearInterval(autoRotationIntervalRef.current);
      }
    };
  }, [checkScreenSize]);

  // Set up auto-rotation when api is available and screen size changes
  useEffect(() => {
    // Clear any existing interval
    if (autoRotationIntervalRef.current) {
      clearInterval(autoRotationIntervalRef.current);
      autoRotationIntervalRef.current = null;
    }

    // Only set up auto-rotation on small screens and if we have podcasts
    if (isSmallScreen && podcastCarouselApi && podcasts.length > 0) {
      autoRotationIntervalRef.current = setInterval(() => {
        podcastCarouselApi.scrollNext();
      }, 3000); // Rotate every 3 seconds
    }

    return () => {
      if (autoRotationIntervalRef.current) {
        clearInterval(autoRotationIntervalRef.current);
      }
    };
  }, [podcastCarouselApi, isSmallScreen, podcasts.length]);
  
  return (
    <div className="space-y-32 max-w-7xl mx-auto px-4">
      <section className="text-center">
        <h2 className="text-[2.75rem] font-quincycf-bold mb-12 tracking-tight">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-[#E8EDED] rounded-2xl p-8 aspect-square flex flex-col items-center justify-center">
            <div className="flex items-center justify-center w-16 h-16 bg-white/80 rounded-xl mb-6">
              <LightBulbIcon className="w-8 h-8 text-gray-700" />
            </div>
            <h3 className="text-2xl font-quincycf-bold mb-4">Idea Extraction</h3>
            <p className="text-gray-600 leading-relaxed max-w-xs">
              AI listens and analyzes podcasts to extract innovative ideas and key insights.
            </p>
            <div className="p-3 mt-4 bg-gray-50 rounded">
              <p className="text-sm text-gray-500">
                "PodThinker found a brilliant marketplace idea in a 3-hour podcast that I completely missed!"
              </p>
              <p className="mt-1 text-sm font-medium">- Harry S, SME Founder (Omkars Friend)</p>
            </div>
          </div>

          <div className="bg-[#E8D9D9] rounded-2xl p-8 aspect-square flex flex-col items-center justify-center">
            <div className="flex items-center justify-center w-16 h-16 bg-white/80 rounded-xl mb-6">
              <ChartBarIcon className="w-8 h-8 text-gray-700" />
            </div>
            <h3 className="text-2xl font-quincycf-bold mb-4">Trend Analysis</h3>
            <p className="text-gray-600 leading-relaxed max-w-xs">
              AI analyzes ideas and insights to create detailed business opportunities with market analysis.
            </p>
            <div className="p-3 mt-4 bg-gray-50 rounded">
              <p className="text-sm text-gray-500">
                "The market size estimates helped me prioritize which ideas to pursue first."
              </p>
              <p className="mt-1 text-sm font-medium">- Claude, Omkar's coding buddy</p>
            </div>
          </div>


          <div className="bg-[#DCD9E8] rounded-2xl p-8 aspect-square flex flex-col items-center justify-center">
            <div className="flex items-center justify-center w-16 h-16 bg-white/80 rounded-xl mb-6">
              <BoltIcon className="w-8 h-8 text-gray-700" />
            </div>
            <h3 className="text-2xl font-quincycf-bold mb-4">Quick Implementation</h3>
            <p className="text-gray-600 leading-relaxed max-w-xs">
              Turn these ideas into action with our step-by-step implementation guides.
            </p>
            <div className="p-3 mt-4 bg-gray-50 rounded">
              <p className="text-sm text-gray-500">
                "The implementation guide saved me weeks of research and planning."
              </p>
              <p className="mt-1 text-sm font-medium">- Gemini 2.0 Flash., Best model</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-12">
          <a
            href="/podcasts"
            className="inline-flex items-center px-6 py-3 rounded-full bg-black text-white hover:opacity-90"
          >
            Explore Podcasts →
          </a>
        </div>
      </section>
      
      <section>
        <h2 className="text-[2.75rem] font-quincycf-bold mb-12 tracking-tight text-center">Latest Podcasts</h2>
        <div className="relative w-full pb-12">
          <Carousel 
            className="w-full" 
            setApi={setPodcastCarouselApi}
            opts={{
              align: 'start',
              loop: true,
            }}
          >
            <CarouselContent className="-ml-4">
              {!isLoading && Array.isArray(podcasts) && podcasts.length > 0 ? (
                podcasts.map((podcast) => (
                  <CarouselItem key={podcast.id} className="pl-4 md:basis-1/2 lg:basis-1/3">
                    <div className="p-1">
                      <PodcastCard podcast={podcast} isAuthenticated={!!user} />
                    </div>
                  </CarouselItem>
                ))
              ) : (
                <CarouselItem className="pl-4">
                  <div className="p-4 text-center text-gray-600">
                    {isLoading ? "Loading podcasts..." : "No podcasts available at the moment"}
                  </div>
                </CarouselItem>
              )}
            </CarouselContent>
            {!isLoading && Array.isArray(podcasts) && podcasts.length > 0 && (
              <div className="flex justify-center gap-4 mt-8">
                <CarouselPrevious />
                <CarouselNext />
              </div>
            )}
          </Carousel>
        </div>
      </section>

      <div className="space-y-32">
              <section>
                <h2 className="text-[2.75rem] font-quincycf-bold mb-12 tracking-tight">The Reality Check</h2>
                <div className="space-y-6">
                  <div className="grid md:grid-cols-[1fr,auto] gap-8 items-center">
                    <div className="bg-[#e3b977] rounded-2xl p-8">
                      <h3 className="text-2xl font-quincycf-bold mb-6">Expectation</h3>
                      <p className="text-xl leading-relaxed">
                        You listen to a podcast → Your brain explodes with billion-dollar ideas → You become the next
                        Elon Musk (minus the Twitter drama)
                      </p>
                    </div>
                    <ScrollImage
                      src={expectation}
                      width={300}
                      height={200}
                      alt="Success illustration"
                      className="mx-auto md:sticky md:top-3 max-w-[250px] md:max-w-[300px]"
                    />
                  </div>
                  <div className="grid md:grid-cols-[auto,1fr] gap-8 items-center">
                    <ScrollImage
                      src={reality}
                      width={300}
                      height={200}
                      alt="Reality illustration"
                      className="mx-auto md:sticky md:top-3 max-w-[250px] md:max-w-[300px]"
                    />
                    <div className="bg-[#bab3cd] rounded-2xl p-8">
                      <h3 className="text-2xl font-quincycf-bold mb-6">Reality</h3>
                      <p className="text-xl leading-relaxed">
                        You listen to a podcast → Your brain explodes with billion-dollar ideas → You think 'I'll definitely remember this' → You absolutely don't remember this → You go back to doom scrolling while shoving pizza in your face → You remain decidedly un-billionaire-like
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-[2.75rem] font-quincycf-bold mb-12 tracking-tight">The Solution</h2>
                <div className="grid md:grid-cols-[1fr,auto] gap-8 items-start">
                  <div className="space-y-12">
                    <div className="bg-[#cb9ea3] rounded-2xl p-8">
                      <h3 className="text-2xl font-semibold mb-6">How we are fixing your podcast induced amnesia:</h3>
                      <div className="space-y-6">
                        <p className="text-xl leading-relaxed">
                          Podthinker is like that friend who actually takes notes in class → It catches every brilliant idea → Transforms your podcast-induced fever dreams into actual business plans → Hands you a blueprint for world domination
                        </p>
                         <p className="text-xl leading-relaxed">
                          (But sorry, you still have to actually 'build' the thing. Its AI, not your Mom.)
                        </p>
                        <p className="text-xl leading-relaxed">
                        Look, I'm not promising these exact ideas will make you the next Silicon Valley wonder child.
                        But at least you'll graduate from 'I have no ideas' to 'I have TOO MANY ideas.'
                      </p>
                       <p className="text-xl leading-relaxed">
                        Stop edging with endless podcasts. Use Podthinker to turn your mental masturbation into a
                        full-blown idea orgasm 🚀
                      </p>
                      </div>
                    </div>

                    
                  </div>
                  <ScrollImage
                    src={sol_meme}
                    alt="Solution illustration"
                    className="mx-auto md:sticky md:top-8 max-w-[250px] md:max-w-[300px]"
                  />
                </div>
              </section>
            </div>

    </div>
  );
};

export default Features;

