import { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { EnhancedIdea } from '../types';
import { IdeaCard } from '../components/idea/IdeaCard';
import { getAllIdeas, getBusinessAreas, saveIdea } from '../services/api';
import { Button } from '../components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';
import { Input } from '../components/ui/input';
import { useDebounce } from '../hooks/useDebounce';
import { useAuth } from '../contexts/AuthContext';
import { useToast } from '../components/ui/use-toast';

// Cache interface
interface CacheItem<T> {
  data: T;
  timestamp: number;
}

// Cache for ideas data
interface IdeasCache {
  [key: string]: CacheItem<{
    items: EnhancedIdea[];
    total_pages: number;
  }>;
}

// Cache for business areas
interface BusinessAreasCache extends CacheItem<string[]> {}

// Cache duration in milliseconds (5 minutes)
const CACHE_DURATION = 5 * 60 * 1000;

// Initialize caches
const ideasCache: IdeasCache = {};
let businessAreasCache: BusinessAreasCache | null = null;

export const ExploreIdeasPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ideas, setIdeas] = useState<EnhancedIdea[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '');
  const [businessAreas, setBusinessAreas] = useState<string[]>([]);
  const debouncedKeyword = useDebounce(keyword, 500);
  
  // Add a ref to track if we've already processed the pending save
  const pendingSaveProcessed = useRef(false);

  const page = Number(searchParams.get('page')) || 1;
  const sortBy = searchParams.get('sortBy') || 'upvotes';
  const sortOrder = searchParams.get('sortOrder') || 'desc';
  const businessArea = searchParams.get('businessArea') || 'ALL';

  // Function to check if cache is valid
  const isCacheValid = <T,>(cache: CacheItem<T> | null): boolean => {
    if (!cache) return false;
    const now = Date.now();
    return now - cache.timestamp < CACHE_DURATION;
  };

  // Generate cache key based on current filters
  const getCacheKey = useCallback((): string => {
    return `${page}_${sortBy}_${sortOrder}_${businessArea}_${debouncedKeyword}`;
  }, [page, sortBy, sortOrder, businessArea, debouncedKeyword]);

  // Function to fetch data
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      
      // Generate cache key for current filters
      const cacheKey = getCacheKey();
      
      // Check if we have valid cached ideas data
      const cachedIdeas = ideasCache[cacheKey];
      const cachedBusinessAreas = businessAreasCache;
      
      let areasResponse: string[] = [];
      let ideasResponse: { items: EnhancedIdea[], total_pages: number };
      
      // Fetch business areas if not cached or cache expired
      if (!isCacheValid(cachedBusinessAreas)) {
        console.log('Fetching business areas from API');
        areasResponse = await getBusinessAreas();
        // Update business areas cache
        businessAreasCache = {
          data: areasResponse,
          timestamp: Date.now()
        };
      } else if (cachedBusinessAreas !== null) {
        console.log('Using cached business areas');
        areasResponse = cachedBusinessAreas.data;
      }
      
      // Fetch ideas if not cached or cache expired
      if (!isCacheValid(cachedIdeas)) {
        console.log('Fetching ideas from API');
        ideasResponse = await getAllIdeas({
          page,
          sortBy,
          sortOrder,
          businessArea: businessArea === 'ALL' ? '' : businessArea,
          keyword: debouncedKeyword,
        });
        
        // Update ideas cache
        ideasCache[cacheKey] = {
          data: ideasResponse,
          timestamp: Date.now()
        };
      } else {
        console.log('Using cached ideas data');
        ideasResponse = cachedIdeas.data;
      }

      setBusinessAreas(areasResponse);
      setIdeas(ideasResponse.items);
      setTotalPages(ideasResponse.total_pages);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
    }
  }, [getCacheKey, page, sortBy, sortOrder, businessArea, debouncedKeyword]);

  // Handle save state changes from IdeaCard components - memoize to prevent unnecessary re-renders
  const handleSaveStateChange = useCallback((ideaId: string, isSaved: boolean) => {
    console.log(`Idea ${ideaId} save state changed to ${isSaved}`);
    setIdeas(prevIdeas => 
      prevIdeas.map(idea => 
        idea.id === ideaId 
          ? { ...idea, is_saved: isSaved } 
          : idea
      )
    );
  }, []);

  // Handle pending save action after login
  useEffect(() => {
    const handlePendingSaveAction = async () => {
      // Only process if user is logged in and we haven't processed it yet
      if (user && !pendingSaveProcessed.current) {
        const pendingSaveIdeaId = sessionStorage.getItem('pendingSaveIdeaId');
        if (pendingSaveIdeaId) {
          // Mark as processed immediately to prevent duplicate processing
          pendingSaveProcessed.current = true;
          
          try {
            console.log(`Processing pending save for idea: ${pendingSaveIdeaId}`);
            await saveIdea(pendingSaveIdeaId);
            
            // Update the idea in the local state if it exists - use the same pattern as handleSaveStateChange
            handleSaveStateChange(pendingSaveIdeaId, true);
            
            toast({
              title: 'Success',
              description: 'Idea saved successfully',
            });
          } catch (error) {
            console.error('Failed to save idea after login:', error);
            toast({
              title: 'Error',
              description: 'Failed to save idea. Please try again.',
              variant: 'destructive',
            });
          } finally {
            // Clear the pending save action
            sessionStorage.removeItem('pendingSaveIdeaId');
          }
        }
      }
    };

    handlePendingSaveAction();
  }, [user, toast, handleSaveStateChange]);

  // Fetch data when filters change
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateSearchParams = (updates: Record<string, string>) => {
    const newParams = new URLSearchParams(searchParams);
    Object.entries(updates).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }
    });
    setSearchParams(newParams);
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams({ page: String(newPage) });
  };

  const handleSortChange = (type: string, value: string) => {
    updateSearchParams({ [type]: value, page: '1' });
  };

  const handleBusinessAreaChange = (value: string) => {
    updateSearchParams({ businessArea: value, page: '1' });
  };

  // Function to manually clear the cache
  const clearCache = useCallback(() => {
    Object.keys(ideasCache).forEach(key => delete ideasCache[key]);
    businessAreasCache = null;
    toast({
      title: 'Cache Cleared',
      description: 'Data will be refreshed from the server.',
    });
    // Trigger a refetch
    fetchData();
  }, [fetchData, toast]);

  return (
    <div className="min-h-screen bg-[#F5F5F2]">
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4 md:mb-0">Explore Ideas</h1>
        </div>

        {/* Search and Filter Controls */}
        <div className="max-w-4xl mx-auto mb-8">
          <div className="flex flex-col md:flex-row gap-4 items-center">
            {/* Search Bar */}
            <div className="w-full md:flex-1">
              <Input
                placeholder="Search ideas..."
                value={keyword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)}
                className="w-full bg-white border-gray-200 text-gray-900 placeholder:text-gray-500"
              />
            </div>

            {/* Business Area Filter */}
            <Select
              value={businessArea}
              onValueChange={handleBusinessAreaChange}
              disabled={loading}
            >
              <SelectTrigger className="w-[160px] bg-white border-gray-200 text-gray-900">
                <SelectValue placeholder="Business Area" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectItem value="ALL">All Areas</SelectItem>
                {businessAreas.map((area) => (
                  <SelectItem key={area} value={area}>
                    {area}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Sort Controls */}
            <div className="flex gap-2 w-full md:w-auto">
              <Select
                value={sortBy}
                onValueChange={(value: string) => handleSortChange('sortBy', value)}
                disabled={loading}
              >
                <SelectTrigger className="w-[140px] bg-white border-gray-200 text-gray-900">
                  <SelectValue placeholder="Sort by" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectItem value="upvotes">Upvotes</SelectItem>
                  <SelectItem value="market_size">Market Size</SelectItem>
                </SelectContent>
              </Select>

              <Select
                value={sortOrder}
                onValueChange={(value: string) => handleSortChange('sortOrder', value)}
                disabled={loading}
              >
                <SelectTrigger className="w-[140px] bg-white border-gray-200 text-gray-900">
                  <SelectValue placeholder="Sort order" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectItem value="desc">Descending</SelectItem>
                  <SelectItem value="asc">Ascending</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        {/* Ideas List - Changed from grid to list view */}
        {loading ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <>
            <div className="grid gap-6 max-w-4xl mx-auto">
              {ideas.length > 0 ? (
                ideas.map((idea) => (
                  <div key={idea.id} className="bg-white rounded-lg shadow-sm">
                    <IdeaCard 
                      idea={idea} 
                      onSaveStateChange={handleSaveStateChange}
                    />
                  </div>
                ))
              ) : (
                <div className="text-center py-12">
                  <p className="text-xl text-gray-600">No ideas found matching your criteria.</p>
                  <Button 
                    variant="outline" 
                    className="mt-4"
                    onClick={() => {
                      setSearchParams(new URLSearchParams());
                      setKeyword('');
                    }}
                  >
                    Clear filters
                  </Button>
                </div>
              )}
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center items-center gap-2 mt-8">
              <Button
                variant="secondary"
                disabled={page === 1 || loading}
                onClick={() => handlePageChange(page - 1)}
                className="bg-white hover:bg-gray-100 text-gray-900 border-gray-200"
              >
                Previous
              </Button>
              
              <span className="flex items-center px-4 text-gray-900">
                Page {page} of {totalPages}
              </span>

              <Button
                variant="secondary"
                disabled={page === totalPages || loading}
                onClick={() => handlePageChange(page + 1)}
                className="bg-white hover:bg-gray-100 text-gray-900 border-gray-200"
              >
                Next
              </Button>
            </div>
          </>
        )}
      </div>
      
      {/* Decorative Elements */}
      <div className="fixed top-40 left-10 w-20 h-20 bg-gray-200 rounded-full blur-3xl opacity-20"></div>
      <div className="fixed top-60 right-10 w-32 h-32 bg-gray-300 rounded-full blur-3xl opacity-20"></div>
      <div className="fixed bottom-40 left-1/4 w-24 h-24 bg-gray-400 rounded-full blur-3xl opacity-20"></div>
    </div>
  );
};