import { useState, useEffect, useCallback } from 'react';
import { Podcast, Episode } from '../../types/index';
import { EpisodeModal } from '../episode/EpisodeModal';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../ui/card';
import { getPodcastNameFromId } from '../../constants/podcastMapping';

interface PodcastCardProps {
  podcast: Podcast;
  isAuthenticated: boolean;
}

const ROTATION_INTERVAL = 2000; // 1 second
const IMAGE_COUNT = 5; // Number of images to try loading per podcast

export const PodcastCard = ({ podcast }: PodcastCardProps) => {
  const navigate = useNavigate();
  const [selectedEpisode, setSelectedEpisode] = useState<Episode | null>(null);
  const [thumbnailUrls, setThumbnailUrls] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const loadLocalImages = async () => {
      const validUrls: string[] = [];

      // Try loading images from 1 to IMAGE_COUNT
      for (let i = 1; i <= IMAGE_COUNT; i++) {
        const imagePath = `/images/podcasts/${podcast.id}/${i}.jpg`;
        try {
          // Test if image exists by trying to load it
          const response = await fetch(imagePath, { method: 'HEAD' });
          if (response.ok) {
            validUrls.push(imagePath);
          }
        } catch (error) {
          console.log(`Image ${i} not found for podcast ${podcast.id}`);
        }
      }

      setThumbnailUrls(validUrls);
    };

    loadLocalImages();
  }, [podcast.id]);

  // Rotate thumbnails
  useEffect(() => {
    if (thumbnailUrls.length <= 1) return;

    const interval = setInterval(() => {
      setCurrentIndex(current => (current + 1) % thumbnailUrls.length);
    }, ROTATION_INTERVAL);

    return () => clearInterval(interval);
  }, [thumbnailUrls.length]);

  const handleClick = () => {
    // Use the custom name if available, otherwise fall back to ID
    const podcastUrlParam = getPodcastNameFromId(podcast.id);
    navigate(`/podcasts/${podcastUrlParam}`);
  };

  return (
    <Card className="group h-full overflow-hidden bg-white/10 hover:bg-white/20 transition-all duration-300 backdrop-blur-sm border-0 shadow-lg hover:shadow-xl">
      <div
        className="relative aspect-video cursor-pointer overflow-hidden"
        onClick={handleClick}
      >
        {thumbnailUrls.length > 0 ? (
          <img
            src={thumbnailUrls[currentIndex]}
            alt={podcast.title}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = '/images/podcast-placeholder.jpg';
            }}
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-br from-purple-500/50 to-blue-500/50 flex items-center justify-center">
            <span className="text-4xl text-white/80">🎙️</span>
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      </div>
      
      <CardContent className="p-4">
        <h3 className="text-lg font-quincycf-bold text-black mb-2 line-clamp-1">{podcast.title}</h3>
        <p className="text-sm text-gray-300 mb-3 line-clamp-2">{podcast.description}</p>
        <div className="flex items-center justify-between text-xs text-gray-400">
         
        </div>
      </CardContent>

      {selectedEpisode && (
        <EpisodeModal
          episode={selectedEpisode}
          onClose={() => setSelectedEpisode(null)}
        />
      )}
    </Card>
  );
}; 