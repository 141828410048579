import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../../lib/supabase';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

interface LoginFormProps {
  redirectTo?: string;
}

export function LoginForm({ redirectTo }: LoginFormProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  // Get returnTo from URL params or sessionStorage or default
  const returnTo = searchParams.get('returnTo') || 
                  sessionStorage.getItem('returnPath') || 
                  redirectTo || 
                  '/';
  
  useEffect(() => {
    // Store the returnTo path in sessionStorage
    if (returnTo && returnTo !== '/') {
      sessionStorage.setItem('returnPath', returnTo);
    }
  }, [returnTo]);

  // Construct the full redirect URL
  const fullRedirectUrl = `${window.location.origin}${returnTo}`;
  
  // Debug log
  console.log('Auth redirect configuration:', {
    returnTo,
    fullRedirectUrl,
    currentLocation: window.location.href,
    searchParams: Object.fromEntries(searchParams.entries()),
    sessionStoragePath: sessionStorage.getItem('returnPath')
  });

  return (
    <div className="w-full max-w-md mx-auto p-6 space-y-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-900 dark:text-white">
          Welcome Back
        </h2>
        <Auth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: '#404040',
                  brandAccent: '#22c55e',
                },
              },
            },
          }}
          providers={['google']}
          redirectTo={fullRedirectUrl}
          onlyThirdPartyProviders
        />
      </div>
    </div>
  );
} 