import React from 'react';

const AboutPage: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16">
      <h1 className="text-4xl font-quincycf-bold mb-8 text-center">About PodThinker</h1>
      
      <div className="space-y-8 text-gray-600">
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Our Mission</h2>
          <p className="leading-relaxed">
            PodThinker is dedicated to transforming the vast knowledge shared in business podcasts into actionable business ideas and insights. 
            Using advanced AI technology, we extract, analyze, and structure valuable information from conversations with industry leaders, entrepreneurs, and experts.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">How It Works</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-medium text-gray-800 mb-2">1. AI-Powered Analysis</h3>
              <p className="leading-relaxed">
                Our sophisticated AI system processes podcast episodes, identifying key business ideas, market opportunities, and strategic insights shared by industry experts.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-medium text-gray-800 mb-2">2. Structured Business Plans</h3>
              <p className="leading-relaxed">
                Each idea is transformed into a structured business plan, complete with market analysis, implementation steps, and potential challenges.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-medium text-gray-800 mb-2">3. Community Engagement</h3>
              <p className="leading-relaxed">
                Users can save ideas, vote on their potential, and engage with a community of entrepreneurs and business enthusiasts.
              </p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Why PodThinker?</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-medium text-gray-800 mb-2">Time-Saving</h3>
              <p className="leading-relaxed">
                Extract valuable insights without listening to hours of content. Our AI summarizes the most important business opportunities and insights.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-medium text-gray-800 mb-2">Structured Analysis</h3>
              <p className="leading-relaxed">
                Get well-organized business plans and market analyses, making it easier to evaluate and act on opportunities.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-medium text-gray-800 mb-2">Expert Insights</h3>
              <p className="leading-relaxed">
                Access insights from successful entrepreneurs and industry leaders, curated and organized for maximum value.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-medium text-gray-800 mb-2">Community-Driven</h3>
              <p className="leading-relaxed">
                Engage with a community of like-minded individuals, share perspectives, and collaborate on business opportunities.
              </p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Get Started</h2>
          <p className="leading-relaxed mb-6">
            Join PodThinker today to discover business opportunities, gain insights from industry experts, and connect with a community of entrepreneurs.
          </p>
          <div className="flex justify-center gap-4">
            <a
              href="/ideas"
              className="inline-flex items-center px-6 py-3 rounded-full bg-black text-white hover:opacity-90 transition-colors"
            >
              Explore Ideas
            </a>
            <a
              href="/login"
              className="inline-flex items-center px-6 py-3 rounded-full border-2 border-black text-black hover:bg-black hover:text-white transition-colors"
            >
              Sign Up
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutPage; 